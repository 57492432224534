import { useUtilsCrmStore } from "../../../store/Crm/utilsCrm";
import { useClientFileStore } from "../../../store/Crm/ClientFile";
import { useEffect, useState } from "react";
import {
  getCommunes,
  getImplicitGroups,
  getJuridicForm,
  getParagraphes,
  getPersonnesFile,
  getPostTitle,
  getVarFileDossier,
  patchPersonnesFile,
  postPersonnesFile,
} from "../../../utils/customerFile";
import { monthMap } from "../../../data/dataInvoice";
import {
  getClasseurs,
  getFolderClasseursTemplateCode,
  getVarFiche,
} from "../../../utils/folders";
import { useExplorer } from "../../../store/Crm/Explorer";
import { useLoadingStore } from "../../../store/utils/loading";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import Info from "../../../assets/icons/info.png";
import {
  errorFetch,
  errorSendNewTime,
  successDossier,
  successUpdateDossier,
} from "../../../utils/toaster";
import Paragraphes from "./Paragraphes";

/**
 * @component
 * @name ClientFile
 * @description A modal that allows you to create and modify a customer file
 * @author Iris Succi
 * @date 2023
 */
const ClientFile = () => {
  // Store
  const { ficRef, setFicRef, DCLE, setDCLE, dosRef } = useExplorer();
  const { isLoading, setIsLoading } = useLoadingStore();
  const { choiceTab, setChoiceTab } = useUtilsCrmStore();
  const {
    filesModel,
    setFilesModel,
    selectedFile,
    setSelectedFile,
    name,
    setName,
    clientSign,
    setClientSign,
    directing,
    setDirecting,
    role,
    setRole,
    adress,
    setAdress,
    cp,
    setCp,
    city,
    setCity,
    country,
    setCountry,
    standarPhone,
    setStandarPhone,
    titlePerson,
    setTitlePerson,
    selectedTitlePerson,
    setSelectedTitlePerson,
    directPhone,
    setDirectPhone,
    mobilePhone,
    setMobilePhone,
    persoPhone,
    setPersoPhone,
    fax,
    setFax,
    email,
    setEmail,
    legalForm,
    setLegalForm,
    selectedLegalForm,
    setSelectedLegalForm,
    commercialRegister,
    setCommercialRegister,
    APECode,
    setAPECode,
    RcsNumber,
    setRcsNumber,
    siretNumber,
    setSiretNumber,
    categoricalRevenue,
    setCategoricalRevenue,
    taxSystem,
    setTaxSystem,
    coreBusiness,
    setCoreBusiness,
    capital,
    setCapital,
    numberOfTitle,
    setNumberOfTitle,
    closingMonth,
    setClosingMonth,
    closing,
    setClosing,
    numberOfEmployees,
    setNumberOfEmployees,
    TvaRegime,
    setTvaRegime,
    numberOfAnnuelInvoice,
    setNumberOfAnnuelInvoice,
    caHtAnnuel,
    setCaHtAnnuel,
    numberOfSupplier,
    setNumberOfSupplier,
    dataFirstPage,
    setDataFirstPage,
    dataSecondPage,
    setDataSecondPage,
    setFicheClient,
    fileNumber,
    setFileNumber,
    updateData,
    setUpdateData,
    numberOfFileDos,
    setNumberOfFileDos,
    varFilesDossier,
    setVarFilesDossier,
    varFiches,
    setVarFiches,
    pentId,
    setPentId,
    openParagraphes,
    setOpenParagraphes,
    setParagraphes,
    communes,
    setCommunes,
    implicitGroups,
    setImplicitesGroupes,
    selectedGroup,
    setSelectedGroup,
  } = useClientFileStore();

  // State pour recuperer la valeur des variables dossier
  const [selectedValues, setSelectedValues] = useState<{
    [key: string]: string;
  }>({});
  /**
   * @function
   * @name useEffect
   * @description Fetches title, juridic form and files model data from the server and sets them to the corresponding states, stored in the clientFileStore store.
   * @author Iris Succi
   * @date 2023
   * @param {Array<string>} dependencies An array of dependencies that will trigger the effect to run.
   */
  useEffect(() => {
    getPostTitle()
      .then((response) => {
        setTitlePerson(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        errorFetch();
        console.log(err);
      });
    getJuridicForm()
      .then((response) => {
        setLegalForm(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        errorFetch();
        console.log(err);
      });
    getClasseurs()
      .then((response) => {
        const filteredClasseurs = response.data.filter(
          (classeur: any) => !classeur.PDOS_NOM.includes("{*}")
        );
        setFilesModel(filteredClasseurs);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        errorFetch();
        console.log(err);
      });

    getVarFileDossier()
      .then((response) => {
        setVarFilesDossier(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        errorFetch();
        console.log(err);
      });

    getCommunes()
      .then((response) => {
        setCommunes(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        errorFetch();
        console.log(err);
      });

    getImplicitGroups()
      .then((response) => {
        const formattedData = response.data.map((group: any) => ({
          value: group.DOS_CODETRAVAIL + " - " + group.DOS_LIBTRAVAIL,
          label: group.DOS_LIBTRAVAIL,
        }));
        setImplicitesGroupes(formattedData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        errorFetch();
        console.log(err);
      });
  }, [choiceTab]);

  // Cest pour les 37000 communes x)
  useEffect(() => {
    if (communes && cp) {
      const commune = communes?.find(
        (item: { PCOM_CODE_POSTAL: string }) => item.PCOM_CODE_POSTAL === cp
      );
      if (commune) {
        setCity(commune.PCOM_NOM);
      }
    } else if (cp == "") {
      setCity("");
    }
  }, [cp, communes]);

  useEffect(() => {
    setFileNumber(0);
    if (selectedFile !== "") {
      getFolderClasseursTemplateCode(selectedFile)
        .then((res) => {
          setNumberOfFileDos(0);
          const typeOfRes = typeof res.data;
          if (typeOfRes === "string") {
            const data = res.data?.match(/\?+/g);
            setNumberOfFileDos(data[0].length);
          } else {
            setFileNumber(res.data);
            setNumberOfFileDos(0);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (pentId !== 0) {
        getVarFiche(selectedFile, pentId)
          .then((response) => {
            setIsLoading(false);
            setVarFiches(response.data);
          })
          .catch((err) => {
            setIsLoading(false);
            errorFetch();
            console.log(err);
          });
      }
    }
  }, [selectedFile]);

  /**
   * @function
   * @name useEffect
   * @description Fetches client data from the server and sets it to the corresponding states, stored in the clientFileStore store.
   * @author Iris Succi
   * @date 2023
   * @param {Array<string>} dependencies An array of dependencies that will trigger the effect to run. When the ficRef id changes
   */
  useEffect(() => {
    if (ficRef === 0) {
      return
    } else {
    setIsLoading(true);
    getPersonnesFile(ficRef)
      .then((res) => {
        const data = res.data;

        // Récupération variable dossier
        if (data?.DOS_VARS) {
          const initialSelectedValues: { [key: string]: string } = {};
          data?.DOS_VARS.forEach((item: any) => {
            initialSelectedValues[
              item.PVD_ID
            ] = `${item.PVDL_CODE};${item.PVDL_LIBELLE}`;
          });
          setSelectedValues(initialSelectedValues);
        }

        // Récupération variable fiche
        if (data.FIC_VARS) {
          const initialInputValues: { [key: string]: string } = {};
          data?.FIC_VARS.forEach((item: any) => {
            initialInputValues[item.id] = `${item.value}`;
          });
          setInputValues(initialInputValues);
        }

        // Récupération variable groupe implicite
        if (data.DOS_CODETRAVAIL !== null || data.DOS_LIBTRAVAIL !== null) {
          setSelectedGroup(`${data.DOS_CODETRAVAIL} - ${data.DOS_LIBTRAVAIL}`);
        }

        setName(data["Nom / Dénomination sociale"]);
        setClientSign(data["PM Enseigne"]);
        setSelectedTitlePerson(data["Appellation courrier"]);
        setDirecting(data["Nom Dirigeant"]);
        setStandarPhone(data["Téléphone standard"]);
        setRole(data["Fonction dirigeant (Libellé)"]);
        setDirectPhone(data["Téléphone direct"]);
        setAdress(data["Adresse principale (Rue)"]);
        setPersoPhone(data["Téléphone personnel"]);
        setMobilePhone(data["Téléphone Portable"]);
        setCp(data["Adresse principale (CP)"]);
        setCity(data["Adresse principale (Ville)"]);
        setCountry(data["Adresse principale (Pays)"]);
        setEmail(data["Email"]);
        setFax(data["Télécopie"]);
        setSiretNumber(data["PM SIRET Numéro"]);
        setSelectedLegalForm(data["PM Forme juridique (Libellé)"]);
        setCommercialRegister(data["PM RCS Numéro"]);
        setAPECode(data["PM Code APE"]);
        setCategoricalRevenue(data["PM Activité"]);
        setRcsNumber(data["PM RCS Numéro"]);
        setTaxSystem(data["Régime TVA (Libellé)"]);
        setCoreBusiness(data["PM Activité"]);
        setCapital(data["PM Capital en Euros"]);
        setNumberOfTitle(data["PM Nombre de titres"]);
        setClosing(data["PM Mois de fin d'exercice social (Libellé)"]);
        setNumberOfEmployees(data["PM Nbre Salariés"]);
        setTvaRegime(data["Régime TVA (Libellé)"]);
        setNumberOfAnnuelInvoice(data["PM Nbre de factures Clients annuel"]);
        setCaHtAnnuel(data["PM CA HT annuel"]);
        setNumberOfSupplier(data["PM Nbre de Factures Fournisseurs Annuel"]);
        setFileNumber(data.DOS_CODE);
        setSelectedFile(data.PDOS_ID);
        setFicheClient(data);
        setDCLE(data.DCLE);
        setDataFirstPage({
          ...dataFirstPage,
          "Nom / Dénomination sociale": data["Nom / Dénomination sociale"],
          "PM Enseigne": data["PM Enseigne"],
          "Appellation courrier": data["Appellation courrier"],
          "Nom Dirigeant": data["Nom Dirigeant"],
          "Téléphone standard": data["Téléphone standard"],
          "Fonction dirigeant (Libellé)": data["Fonction dirigeant (Libellé)"],
          "Téléphone direct": data["Téléphone direct"],
          "Adresse principale (Rue)": data["Adresse principale (Rue)"],
          "Téléphone personnel": data["Téléphone personnel"],
          "Téléphone Portable": data["Téléphone Portable"],
          "Adresse principale (CP)": data["Adresse principale (CP)"],
          "Adresse principale (Ville)": data["Adresse principale (Ville)"],
          "Adresse principale (Pays)": data["Adresse principale (Pays)"],
          Email: data.Email,
          Télécopie: data.Télécopie,
          DOS_CODE: data.DOS_CODE,
          PDOS_ID: data.PDOS_ID,
        });
        setDataSecondPage({
          ...dataSecondPage,
          "PM SIRET Numéro": data["PM SIRET Numéro"],
          "PM Revenus catégoriels": data["PM Revenus catégoriels"],
          "PM Régime Fiscal": data["PM Régime Fiscal"],
          "Reprise Clôture": data["Reprise Clôture"],
          "PM Forme juridique (Libellé)": data["PM Forme juridique (Libellé)"],
          "PM Code APE": data["PM Code APE"],
          "PM RCS Numéro": data["PM RCS Numéro"],
          "PM Activité": data["PM Activité"],
          "PM Capital en Euros": data["PM Capital en Euros"],
          "PM Nbre Salariés": data["PM Nbre Salariés"],
          "PM Nombre de titres": data["PM Nombre de titres"],
          "PM Mois de fin d'exercice social (Libellé)":
            data["PM Mois de fin d'exercice social (Libellé)"],
          "Régime TVA (Libellé)": data["Régime TVA (Libellé)"],
          "PM Nbre de factures Clients annuel":
            data["PM Nbre de factures Clients annuel"],
          "PM CA HT annuel": data["PM CA HT annuel"],
          "PM Nbre de Factures Fournisseurs Annuel":
            data["PM Nbre de Factures Fournisseurs Annuel"],
        });

        getParagraphes(dosRef)
          .then((res) => {
            setParagraphes(res.data);
          })
          .catch((err) => {
            console.log(err);
          });

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        errorFetch();
        console.log(err);
      });
    }
  }, [ficRef, openParagraphes]);

  /**
   * @function
   * @name handlePostFile
   * @description Submits the client file data to the server.
   * @author Iris Succi
   * @date 2024
   * @param {object} data The data to be submitted.
   */
  const handlePostFile = () => {
    setIsLoading(true);
    let DOS_CODETRAVAIL;
    let DOS_LIBTRAVAIL;

    if (selectedGroup !== "")
      [DOS_CODETRAVAIL, DOS_LIBTRAVAIL] = selectedGroup.split(" - ");

    const data = {
      ...dataFirstPage,
      ...dataSecondPage,
      DOS_VARCODE: selectedValues,
      FIC_VAR: inputValues,
      DOS_CODE: fileNumber.toString(),
      DOS_CODETRAVAIL,
      DOS_LIBTRAVAIL,
    };
    postPersonnesFile(data)
      .then(() => {
        successDossier();
        handleResetInputs();
        handleDeleteObject();
        setIsLoading(false);
        setChoiceTab("");
      })
      .catch((error) => {
        errorSendNewTime();
        console.log(error);
      });
  };

  /**
   * @function
   * @name handleDeleteObject
   * @description Function that resets the dataFirstPage and dataSecondPage states to their initial values.
   * @author Iris Succi
   * @date 2023
   */
  const handleDeleteObject = () => {
    setDataFirstPage({
      ...dataFirstPage,
      "Nom / Dénomination sociale": "",
      "PM Enseigne": "",
      "Appellation courrier": "",
      "Nom Dirigeant": "",
      "Téléphone standard": "",
      "Téléphone direct": "",
      "Adresse principale (Rue)": "",
      "Téléphone personnel": "",
      "Téléphone Portable": "",
      "Adresse principale (CP)": "",
      "Adresse principale (Ville)": "",
      "Adresse principale (Pays)": "",
      Email: "",
      Télécopie: "",
      DOS_CODE: 0,
      PDOS_ID: 0,
    });
    setDataSecondPage({
      ...dataSecondPage,
      "PM Forme juridique (Libellé)": "",
      "PM SIRET Numéro": "",
      "PM Revenus catégoriels": "",
      "PM Régime Fiscal": "",
      "PM Code APE": "",
      "PM RCS Numéro": "",
      "PM Activité": "",
      "PM Capital en Euros": 0,
      "PM Nbre Salariés": 0,
      "PM Nombre de titres": 0,
      "PM Mois de fin d'exercice social (Libellé)": "",
      "Régime TVA (Libellé)": "",
      "PM Nbre de factures Clients annuel": 0,
      "PM CA HT annuel": 0,
      "PM Nbre de Factures Fournisseurs Annuel": 0,
    });
  };

  /**
   * @function
   * @name handleResetInputs
   * @description Function that resets the input states to their initial values, stored in the clientFileStore store.
   * @author Iris Succi
   * @date 2023
   */
  const handleResetInputs = () => {
    setName("");
    setClientSign("");
    setDirecting("");
    setRole("");
    setAdress("");
    setCp("");
    setCity("");
    setCountry("");
    setStandarPhone("");
    setSelectedTitlePerson("");
    setDirectPhone("");
    setMobilePhone("");
    setPersoPhone("");
    setFax("");
    setEmail("");
    setSelectedLegalForm("");
    setCommercialRegister("");
    setAPECode("");
    setRcsNumber("");
    setCategoricalRevenue("");
    setTaxSystem("");
    setCoreBusiness("");
    setCapital(0);
    setNumberOfTitle(0);
    setClosing("");
    setNumberOfEmployees(0);
    setTvaRegime("");
    setNumberOfAnnuelInvoice(0);
    setCaHtAnnuel(0);
    setNumberOfSupplier(0);
    setSelectedFile(0);
    setPentId(0);
    setSelectedValues({});
    setInputValues({});
    setSelectedGroup("");
  };

  useEffect(() => {
    handleResetInputs();
    handleDeleteObject();
  }, [choiceTab]);

  /**
   * @function
   * @name handleUpdateFile
   * @description Updates the client file data on the server.
   * @author Iris Succi
   * @date 2023
   */

  const handleUpdateFile = () => {
    // Extraire DOS_CODE et PDOS_ID de dataFirstPage, et conserve le reste
    const { DOS_CODE, PDOS_ID, ...restOfDataFirstPage } = dataFirstPage;
    let DOS_CODETRAVAIL;
    let DOS_LIBTRAVAIL;

    if (selectedGroup !== "") {
      [DOS_CODETRAVAIL, DOS_LIBTRAVAIL] = selectedGroup.split(" - ");
    }

    const data = {
      ...restOfDataFirstPage,
      ...dataSecondPage,
      DOS_VARCODE: selectedValues,
      FIC_VAR: inputValues,
      DOS_CODETRAVAIL,
      DOS_LIBTRAVAIL,
    };
    setIsLoading(true);
    patchPersonnesFile(DCLE, data)
      .then(() => {
        handleResetInputs();
        handleDeleteObject();
        setChoiceTab("Fiche client");
        setUpdateData(false);
        setIsLoading(false);
        successUpdateDossier();
        setDCLE(0);
      })
      .catch((error) => {
        errorSendNewTime();
        setIsLoading(false);
        console.log(error);
      });
  };

  // Fonction pour gérer la sélection d'une option
  const handleSelectChange = (name: string, value: string) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Fonction qui affiche les selects de chaque element avec les valeurs correspondantes
  const handleDisplayVarDos = (): JSX.Element | null => {
    if (varFilesDossier?.length > 0) {
      return (
        <>
          {varFilesDossier?.map((variable: any) => (
            <div key={variable.PVD_ID} className="w-full flex flex-col">
              <p>{variable.PVD_NAME}</p>
              <select
                name={variable.PVD_NAME}
                id={variable.PVD_ID}
                value={selectedValues[variable.PVD_ID] || ""}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                className="border-[1px] rounded-sm w-1/2 p-2"
                onChange={(e) =>
                  handleSelectChange(variable.PVD_ID, e.target.value)
                } // Mise à jour du state
              >
                <option value="" className="text-slate-300 "></option>
                {variable.PVDL?.map((item: any) => (
                  <option
                    key={item.PVDL_CODE}
                    value={item.PVDL_CODE + ";" + item?.PVDL_LIBELLE}
                  >
                    {item?.PVDL_LIBELLE}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  //State pour recuperer les donnees de chaque FIC_VAR
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});

  const handleChange = (id: any, value: string) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value, // Met à jour la valeur de l'input correspondant
    }));
  };

  //Fonction qui affiche les valeurs
  const handleDisplayVarFiche = (): JSX.Element | null => {
    if (varFiches?.length > 0) {
      return (
        <>
          {varFiches?.map((variable: any, index: number) => (
            <div className=" w-full flex flex-col" key={index}>
              <p>{variable.name}</p>
              {variable.type === "Texte" ? (
                <input
                  type="text"
                  className="border-[1px] rounded-sm w-1/2 p-2"
                  disabled={
                    choiceTab === "Fiche client" && !updateData ? true : false
                  }
                  value={inputValues[variable.id] || ""}
                  onChange={(e) => handleChange(variable.id, e.target.value)}
                />
              ) : variable.type === "Date" ? (
                <input
                  type="date"
                  className="border-[1px] rounded-sm w-1/2 p-2"
                  disabled={
                    choiceTab === "Fiche client" && !updateData ? true : false
                  }
                  value={inputValues[variable.id] || ""}
                  onChange={(e) => handleChange(variable.id, e.target.value)}
                />
              ) : variable.type === "Liste de valeurs" ? (
                <select
                  name={variable.name}
                  id={variable.name}
                  className="border-[1px] rounded-sm w-1/2 p-2"
                  disabled={
                    choiceTab === "Fiche client" && !updateData ? true : false
                  }
                  value={inputValues[variable.id] || ""}
                  onChange={(e) => handleChange(variable.id, e.target.value)}
                >
                  {variable.values.map((items: any, i: any) => (
                    <option key={i} value={items}>
                      {items}
                    </option>
                  ))}
                </select>
              ) : null}
            </div>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  console.log("choiceTab", choiceTab)
  console.log("ficRef", ficRef)
  
  return choiceTab === "Create" ||
    (choiceTab === "Fiche client" && ficRef !== 0) ? (
    <div className="2xl:relative w-full h-full flex flex-col bg-bc-lightGrey justify-start items-center border-[1px] border-bc-lightGrey rounded-md overflow-y-scroll text-sm">
      {choiceTab === "Create" ? (
        <p className="mt-2 font-bold text-lg text-bc-darkGrey">
          Création d'un dossier client
        </p>
      ) : (
        <div className="flex relative w-full my-8">
          <button
            className="absolute left-2 top-0"
            onClick={() => setChoiceTab("Analytique")}
          >
            <img
              src={Info}
              alt="informations"
              className="2xl:w-10 2xl:h-10 w-6 h-6"
            />
          </button>
          <p className="absolute font-bold text-lg text-bc-darkGrey left-1/2 mt-2">
            Fiche client
          </p>
          {choiceTab === "Fiche client" && !updateData ? (
            <button
              className=" absolute right-10 w-40 mt-2 h-9 bg-bc-green rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
              onClick={() => {
                setUpdateData(true);
              }}
            >
              Modifier
            </button>
          ) : null}
        </div>
      )}
      {!isLoading ? (
        <div className="w-full m-auto flex flex-col justify-center items-center">
          <div className="grid grid-cols-2 gap-5 w-11/12 xl:mt-10 mt-5">
            {choiceTab === "Create" ? (
              <div className="col-start-1 col-end-2 w-full flex flex-col">
                <p>Modèle de dossier :</p>
                <select
                  name="modelFile"
                  id="modelFile"
                  value={selectedFile}
                  className="border-[1px] rounded-sm w-1/2 p-2"
                  onChange={(e) => {
                    const selectedPDOS_ID = Number(e.target.value);

                    // Trouve l'objet correspondant à PDOS_ID
                    const selectedModel = filesModel.find(
                      (model: any) => model.PDOS_ID === selectedPDOS_ID
                    );

                    // Met à jour selectedFile et dataFirstPage
                    setSelectedFile(selectedPDOS_ID);
                    setDataFirstPage({
                      ...dataFirstPage,
                      PDOS_ID: selectedPDOS_ID,
                    });

                    // Si le modèle est trouvé, met à jour PENT_ID
                    if (selectedModel) {
                      setPentId(selectedModel.PENT_ID);
                    }
                  }}
                >
                  <option value="" className="text-slate-300 "></option>
                  {filesModel?.map((model: any, i: any) => (
                    <option key={i} value={model.PDOS_ID}>
                      {model?.PDOS_NOM}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}

            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Nom du dossier :</p>
              <input
                type="text"
                name="name"
                value={name}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                id="name"
                className="border-[1px] rounded-sm w-1/2 p-2"
                onChange={(e) => {
                  setName(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "Nom / Dénomination sociale": e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Numéro de dossier :</p>
              <input
                type="text"
                name="fileNumber"
                maxLength={numberOfFileDos}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                value={fileNumber}
                id="fileNumber"
                onChange={(e) => {
                  if (numberOfFileDos !== 0) {
                    setFileNumber(Number(e.target.value));
                  } else {
                    setFileNumber(Number(e.target.value));
                  }
                  setDataFirstPage({
                    ...dataFirstPage,
                    DOS_CODE: Number(e.target.value),
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Enseigne :</p>
              <input
                type="text"
                name="clientSign"
                value={clientSign}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                id="clientSign"
                onChange={(e) => {
                  setClientSign(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "PM Enseigne": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Appellation courrier :</p>
              <select
                name="appellationCourier"
                id="appellationCourier"
                value={selectedTitlePerson}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                className="border-[1px] rounded-sm w-1/2 p-2"
                onChange={(e) => {
                  setSelectedTitlePerson(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "Appellation courrier": e.target.value,
                  });
                }}
              >
                <option value="" className="text-slate-300 "></option>
                {titlePerson?.length > 0
                  ? titlePerson?.map((title: any, index: number) => (
                      <option key={index}>{title}</option>
                    ))
                  : null}
              </select>
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Dirigeant :</p>
              <input
                type="text"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                name="Nom Dirigeant"
                value={directing}
                id="directing"
                onChange={(e) => {
                  setDirecting(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "Nom Dirigeant": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Téléphone standard :</p>
              <input
                type="text"
                name="standarPhone"
                value={standarPhone}
                id="standarPhone"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                onChange={(e) => {
                  setStandarPhone(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "Téléphone standard": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Fonction :</p>
              <input
                type="text"
                name="Fonction dirigeant (Libellé)"
                value={role}
                id="role"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                onChange={(e) => {
                  setRole(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "Fonction dirigeant (Libellé)": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Téléphone direct :</p>
              <input
                type="text"
                name="directPhone"
                value={directPhone}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                id="directPhone"
                onChange={(e) => {
                  setDirectPhone(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "Téléphone direct": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Adresse :</p>
              <textarea
                name="adress"
                value={adress}
                id="adress"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                onChange={(e) => {
                  setAdress(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "Adresse principale (Rue)": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-2/3 p-2 h-full"
              />
            </div>
            <div className="col-start-2 col-end-3 w-ful flex flex-col">
              <p>Téléphone personnel :</p>
              <input
                type="text"
                name="persoPhone"
                id="persoPhone"
                value={persoPhone}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                onChange={(e) => {
                  setPersoPhone(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "Téléphone personnel": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
              <p className="mt-2">Téléphone portable :</p>
              <input
                type="text"
                name="mobilePhone"
                value={mobilePhone}
                id="mobilePhone"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                onChange={(e) => {
                  setMobilePhone(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "Téléphone Portable": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2 "
              />
            </div>
            <div className="col-start-1 col-end-2 w-full flex">
              <div className="flex flex-col">
                <p>Code postal :</p>
                <input
                  type="text"
                  name="cp"
                  value={cp}
                  id="cp"
                  disabled={
                    choiceTab === "Fiche client" && !updateData ? true : false
                  }
                  onChange={(e) => {
                    setCp(e.target.value);
                    setDataFirstPage({
                      ...dataFirstPage,
                      "Adresse principale (CP)": e.target.value,
                    });
                  }}
                  className="border-[1px] rounded-sm w-[50%] p-2 mr-4"
                />
              </div>
              <div className="flex flex-col">
                <p>Ville :</p>
                <input
                  type="text"
                  name="city"
                  disabled={
                    choiceTab === "Fiche client" && !updateData ? true : false
                  }
                  id="city"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                    setDataFirstPage({
                      ...dataFirstPage,
                      "Adresse principale (Ville)": e.target.value,
                    });
                  }}
                  className="border-[1px] rounded-sm w-full p-2"
                />
              </div>
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Email :</p>
              <input
                type="text"
                name="email"
                id="email"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    Email: e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Pays :</p>
              <input
                type="text"
                name="country"
                value={country}
                placeholder="Pays"
                id="country"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                onChange={(e) => {
                  setCountry(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    "Adresse principale (Pays)": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Fax :</p>
              <input
                type="text"
                name="fax"
                placeholder="Fax"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                id="fax"
                value={fax}
                onChange={(e) => {
                  setFax(e.target.value);
                  setDataFirstPage({
                    ...dataFirstPage,
                    Télécopie: e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5 w-11/12 xl:mt-10 mt-5">
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Forme juridique :</p>
              <select
                name="legalForm"
                id="legalForm"
                value={selectedLegalForm}
                className="border-[1px] rounded-sm w-1/2 p-2"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                onChange={(e) => {
                  setSelectedLegalForm(e.target.value);
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Forme juridique (Libellé)": e.target.value,
                  });
                }}
              >
                <option value="" className="text-slate-300 "></option>
                {legalForm?.length > 0
                  ? legalForm.map((form: string, i: any) => (
                      <option key={i}>{form}</option>
                    ))
                  : null}
              </select>
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Regristre de commerce :</p>
              <input
                type="text"
                name="commercialRegister"
                value={commercialRegister}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                id="commercialRegister"
                onChange={(e) => {
                  setCommercialRegister(e.target.value);
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Code APE</p>
              <input
                type="text"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                name="APECode"
                value={APECode}
                id="APECode"
                onChange={(e) => {
                  setAPECode(e.target.value);
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Code APE": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Revenus catégoriels :</p>
              <input
                type="text"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                name="categoricalRevenue"
                id="categoricalRevenue"
                value={categoricalRevenue}
                onChange={(e) => {
                  setCategoricalRevenue(e.target.value);
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Revenus catégoriels": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Numéro RCS :</p>
              <input
                type="text"
                name="RcsNumber"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                value={RcsNumber}
                id="RcsNumber"
                onChange={(e) => {
                  setRcsNumber(e.target.value);
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM RCS Numéro": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Regime fiscal :</p>
              <input
                type="text"
                name="taxSystem"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                value={taxSystem}
                id="taxSystem"
                onChange={(e) => {
                  setTaxSystem(e.target.value);
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Régime Fiscal": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Siret :</p>
              <input
                type="text"
                name="siretNumber"
                disabled={choiceTab === "Fiche client" ? true : false}
                value={siretNumber}
                id="siretNumber"
                onChange={(e) => {
                  setSiretNumber(e.target.value);
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM SIRET Numéro": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-1 col-end-2 w-full m-auto flex flex-col">
              <p>Activité principale :</p>
              <textarea
                name="coreBusiness"
                value={coreBusiness}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                id="coreBusiness"
                onChange={(e) => {
                  setCoreBusiness(e.target.value);
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Activité": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-2/3 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Capital</p>
              <input
                type="number"
                name="capital"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                value={capital}
                id="capital"
                onChange={(e) => {
                  setCapital(Number(e.target.value));
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Capital en Euros": Number(e.target.value),
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Nombre de salariés :</p>
              <input
                type="number"
                name="numberOfEmployees"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                value={numberOfEmployees}
                id="numberOfEmployees"
                onChange={(e) => {
                  setNumberOfEmployees(Number(e.target.value));
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Nbre Salariés": Number(e.target.value),
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Nombre de titre :</p>
              <input
                type="number"
                name="numberOfTitle"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                value={numberOfTitle}
                id="numberOfTitle"
                onChange={(e) => {
                  setNumberOfTitle(Number(e.target.value));
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Nombre de titres": Number(e.target.value),
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Mois de clôture :</p>
              <select
                name="clotureMonth"
                value={closingMonth}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                id="clotureMonth"
                className="border-[1px] rounded-sm w-1/2 p-2"
                onChange={(e) => {
                  setClosingMonth(e.target.value);
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Mois de fin d'exercice social (Libellé)":
                      e.target.value,
                  });
                }}
              >
                <option value="" className="text-slate-300 "></option>
                {Object.keys(monthMap).map((month, i) => (
                  <option key={i} value={monthMap[month]}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Clôture :</p>
              <input
                type="text"
                name="closing"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                value={closing}
                id="closing"
                onChange={(e) => {
                  setClosing(e.target.value);
                  setDataSecondPage({
                    ...dataSecondPage,
                    "Reprise Clôture": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>

            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>Régime TVA :</p>
              <input
                type="text"
                name="TvaRegime"
                value={TvaRegime}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                id="TvaRegime"
                onChange={(e) => {
                  setTvaRegime(e.target.value);
                  setDataSecondPage({
                    ...dataSecondPage,
                    "Régime TVA (Libellé)": e.target.value,
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Nombre de factures annuelles :</p>
              <input
                type="number"
                name="numberOfAnnuelInvoice"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                value={numberOfAnnuelInvoice}
                id="numberOfAnnuelInvoice"
                onChange={(e) => {
                  setNumberOfAnnuelInvoice(Number(e.target.value));
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Nbre de factures Clients annuel": Number(
                      e.target.value
                    ),
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-1 col-end-2 w-full flex flex-col">
              <p>CA HT annuel :</p>
              <input
                type="number"
                name="caHtAnnuel"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                value={caHtAnnuel}
                id="caHtAnnuel"
                onChange={(e) => {
                  setCaHtAnnuel(Number(e.target.value));
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM CA HT annuel": Number(e.target.value),
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>
            <div className="col-start-2 col-end-3 w-full flex flex-col">
              <p>Nombre de fournisseur annuel :</p>
              <input
                type="number"
                name="numberOfSupplier"
                value={numberOfSupplier}
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                id="numberOfSupplier"
                onChange={(e) => {
                  setNumberOfSupplier(Number(e.target.value));
                  setDataSecondPage({
                    ...dataSecondPage,
                    "PM Nbre de Factures Fournisseurs Annuel": Number(
                      e.target.value
                    ),
                  });
                }}
                className="border-[1px] rounded-sm w-1/2 p-2"
              />
            </div>

            <div className="w-full flex flex-col">
              <p>Groupe implicite :</p>
              <select
                name="implicitegroup"
                id="implicitegroup"
                value={selectedGroup}
                className="border-[1px] rounded-sm w-1/2 p-2"
                disabled={
                  choiceTab === "Fiche client" && !updateData ? true : false
                }
                onChange={(e) => {
                  setSelectedGroup(e.target.value);
                }}
              >
                <option value="" className="text-slate-300 "></option>
                {implicitGroups?.map((item: any, i: any) => (
                  <option key={i} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            {handleDisplayVarDos()}

            {handleDisplayVarFiche()}

            {choiceTab === "Fiche client" ? (
              <button
                className="border-[1px] rounded-sm w-1/2 p-2 bg-white shadow-md h-10 mt-3 mb-5"
                onClick={() => setOpenParagraphes(true)}
              >
                Paragraphes
              </button>
            ) : null}

            {openParagraphes ? <Paragraphes /> : null}

            {choiceTab === "Create" && !updateData && (
              <div className="w-full flex justify-center items-center my-10 col-start-2 col-end-3">
                <button
                  className="w-40 mt-2 h-9 bg-red-500 rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
                  onClick={() => {
                    handleDeleteObject();
                    handleResetInputs();
                    setChoiceTab("Fiche client");
                    setFicRef(0);
                  }}
                >
                  Annuler
                </button>
                <button
                  className="w-40 mt-2 h-9 bg-bc-green rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
                  onClick={handlePostFile}
                >
                  Créer
                </button>
              </div>
            )}
            {choiceTab === "Fiche client" && updateData ? (
              <div className="w-full flex justify-center items-center my-10 col-start-2">
                {" "}
                <button
                  className="w-40 mt-2 h-9 bg-red-500 rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
                  onClick={() => {
                    handleDeleteObject();
                    setChoiceTab("Fiche client");
                    handleResetInputs();
                    setUpdateData(false);
                    setFicRef(0);
                    setDCLE(0);
                  }}
                >
                  Annuler
                </button>
                <button
                  className="w-40 mt-2 h-9 bg-bc-green rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
                  onClick={handleUpdateFile}
                >
                  Modifier
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="h-full w-full flex justify-center items-center">
          <CircleLoarder />
        </div>
      )}
    </div>
  ) : null;
};

export default ClientFile;
