/* eslint-disable no-irregular-whitespace */
import { useTabsFSStore } from "../../../store/FinancialStatement/tabsStore";
import YearSelect from "../select/YearSelect";
import GroupeSelect from "../select/GroupeSelect";
import UserSelect from "../select/UserSelect";
import ClientFileSelect from "../select/ClientFileSelect";

/**
 * @component
 * @name Workload
 * @description
  * Un composant qui génère un rapport PDF pour le type de charge de travail sélectionné.
  * Les types de charge de travail pris en charge sont :
  *
  * - **Plan de charge par collab :** Affiche un rapport de charge de travail pour un collaborateur spécifique.
  * - **Plan de charge synthétique :** Fournit un résumé de la charge de travail de l'entreprise.
  * - **Plan de charge par dossier :** Affiche la charge de travail pour un dossier client spécifique.
  *
  * Le composant permet également aux utilisateurs de filtrer le rapport par année et
  * de sélectionner le groupe, le collaborateur et le dossier client.''
  * Remarque : ce composant n'est pas utilisé dans la version actuelle de l'application.
 * @author Iris Succi
 * @date 2023
 */
const Workload = () => {
  const { workloadTypes, workloadTypeSelected, setWorkloadTypeSelected } =
    useTabsFSStore();

  return (
    <div className="w-[540px] h-[80%] border-[1px] ">
      <div className="w-11/12 m-auto flex flex-col justify-start items-start">
        <p className="mt-5 font-semibold">Type d'export :</p>
        <select
          name="type"
          id="type"
          className="border-[1px] h-9 rounded-sm mt-2 p-1 font-thin"
          onChange={(e) => setWorkloadTypeSelected(e.target.value)}
        >
          {workloadTypes.map((data) => (
            <option value={data}>{data}</option>
          ))}
        </select>

        <p className="mt-10 font-semibold">Année civile :</p>
        <YearSelect />

        {workloadTypeSelected === "Plan de charge par collab" ||
        workloadTypeSelected === "Plan de charge synthétique" ? (
          <>
            <p className="mt-10 font-semibold mb-2">Groupe :</p>
            <GroupeSelect />
            <p className="mt-10 font-semibold mb-2">Collaborateur :</p>
            <UserSelect />
          </>
        ) : null}

        {workloadTypeSelected === "Plan de charge par dossier" ? (
          <>
            <p className="mt-10 font-semibold mb-2">Dossier :</p>
            <ClientFileSelect />
          </>
        ) : null}
      </div>
      <div className="w-full flex justify-center items-center mt-10">
        <button className="w-40 h-8 border-[1px] rounded-sm border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer">
          Générer le rapport
        </button>
      </div>
    </div>
  );
};

export default Workload;
