import { useEffect, useState } from "react";
import { delphi2html_color } from "../../../utils/TimeManager/timeUtils";
import check from "../../../assets/icons/check.png";
import exclamation from "../../../assets/icons/exclamation.png";
import CircleLoarder from "../loader/CircleLoarder";
import { useSearchBarStore } from "../../../store/utils/searchBar";
import { useLoadingStore } from "../../../store/utils/loading";
import { useDataStore } from "../../../store/TimeManagement/dataStore";
import { useListStore } from "../../../store/TimeManagement/listStore";
import {
  columnMappings,
  orderedColumns,
} from "../../../data/dataSelectItemDisplayListTime";
import ManualValidationTime from "../modals/ManualValidationTime";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";

/**
 * @component
 * @name ListView
 * @author Succi Iris
 * @date 2023
 * @description
 * Le composant `ListView` permet d'afficher la liste des temps de travail.
 */
const ListView = ({setRenderData}: any) => {
  // store
  const { isLoading } = useLoadingStore();
  const { search } = useSearchBarStore();
  const { dataMonth } = useDataStore();
  const {
    dataFilterList,
    selectedItems,
    currentItems,
    setCurrentItems,
    filteredData,
    setFilteredData,
    selectedTimeForValidated,
    setSelectedTimeForValidated,
    selectedAllTimeForValidated,
    setSelectedAllTimeForValidated,
    setBlockSelectedTimeForValidated,
  } = useListStore();
  const { displayDiv } = useCreateInvoiceStore();

  // state
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const itemsPerPage = 25;

  const totalPageCount = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    setCurrentItems(filteredData.slice(indexOfFirstItem, indexOfLastItem));
  }, [filteredData, indexOfFirstItem, indexOfLastItem]);

  const sortedSelectedItems = orderedColumns.filter((column) =>
    selectedItems.includes(column)
  );

  // Sert à filtrer les données en fonction du dossier dans la barre de recherche
  useEffect(() => {
    let data = dataMonth.length > 0 ? [...dataMonth] : [...dataFilterList];
    if (search !== "") {
      data = data.filter((item) =>
        item["TD_FIC_TITRE_CLIENT"].toLowerCase().includes(search.toLowerCase())
      );
    }
    data.sort((a, b) => {
      const dateA = new Date(a.AA_ANA_DATEREALISATION).getTime();
      const dateB = new Date(b.AA_ANA_DATEREALISATION).getTime();
      return dateB - dateA;
    });
    setFilteredData(data);
  }, [search, dataMonth, dataFilterList]);

  // Sert à rafraichir la page quand la barre de recherche change
  useEffect(() => {
    if (search !== "") {
      setCurrentPage(1);
      setStartPage(1);
      setEndPage(Math.min(10, totalPageCount));
    }
  }, [search, totalPageCount, setStartPage, setEndPage, setCurrentPage]);

  const handleCheckboxChange = (item: any, isChecked: any) => {
    const { AA_REF, AA_DOS_REF, AA_REDACTEUR } = item; // Extrait les champs nécessaires
    const newItem = {
      AA_REF: AA_REF,
      DOS_REF: AA_DOS_REF,
      DOS_TITRE: item.TD_FIC_TITRE_CLIENT,
      UTI_CODE: AA_REDACTEUR,
      AA_ANA_EMTHT: item.AA_ANA_EMTHT,
    };

    if (isChecked) {
      setSelectedTimeForValidated((prev) => [...prev, newItem]);
      // Ajoute l'élément à l'état si la case est cochée
    } else {
      // Retire l'élément de l'état si la case est décochée
      setSelectedTimeForValidated((prev) =>
        prev.filter((el) => el.AA_REF !== item.AA_REF)
      );
    }
  };

  const handleSelectedAllTimes = () => {
    setSelectedAllTimeForValidated((prev) => !prev);

    if (!selectedAllTimeForValidated) {
      const allItems = currentItems.map((item) => ({
        AA_REF: item.AA_REF,
        DOS_REF: item.AA_DOS_REF,
        DOS_TITRE: item.TD_FIC_TITRE_CLIENT,
        UTI_CODE: item.AA_OPERATEUR,
      }));
      setSelectedTimeForValidated(allItems);
      setBlockSelectedTimeForValidated(true);
    } else {
      setSelectedTimeForValidated([]);
      setBlockSelectedTimeForValidated(false);
    }
  };

  // Sert a bloquer la validation manuelle si les temps sélectionnés ne sont pas du même dossier
  useEffect(() => {
    const allEqual = selectedTimeForValidated.every((el) => el.DOS_REF === selectedTimeForValidated[0].DOS_REF);
    
    if (allEqual) {
      setBlockSelectedTimeForValidated(false);
    } else {
      setBlockSelectedTimeForValidated(true);
    }
  }, [selectedTimeForValidated]);

  return (
    <div className="relative w-[98%] h-full overflow-y-scroll border-[1px] rounded-md mr-1 ">
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
          <CircleLoarder />
        </div>
      ) : (
        <div className="w-full flex flex-col h-full">
          <table className="w-full text-sm divide-y divide-gray-200 ">
            <thead className="bg-[#cecece] sticky top-0 h-14 pt-4">
              <tr>
                {sortedSelectedItems.map((item, index) => (
                  <th
                    key={index}
                    className="p-2 border-r-[1px] border-b-[1px] text-left"
                  >
                    {item === "Etat" ? (
                      <div className="flex justify-start items-center">
                        <p className="mr-2">{item}</p>
                        <input
                          type="checkbox"
                          checked={selectedAllTimeForValidated}
                          onClick={() => handleSelectedAllTimes()}
                        />
                      </div>
                    ) : (
                      item
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 h-full">
              {currentItems?.map((item: any, index: any) => (
                <tr
                  key={index}
                  className={`h-10 ${
                    index % 2 === 0 ? "bg-grey-Array" : "bg-white"
                  }`}
                  style={
                    index === currentItems.length - 1
                      ? { borderBottom: "1px solid #cecece" }
                      : {}
                  }
                >
                  {sortedSelectedItems?.map((selectedItem, selectedIndex) => (
                    <td key={selectedIndex} className="p-2">
                      {selectedItem === "Etat" ? (
                        <div className="flex justify-center items-center w-full">
                          <div
                            className="2xl:w-5 2xl:h-5 w-3 h-3 rounded-full border-[1px] "
                            style={{
                              backgroundColor: delphi2html_color(
                                item[columnMappings[selectedItem][0]]
                              ),
                            }}
                          />

                          <span className="2xl:ml-1">
                            {item[columnMappings[selectedItem][1]] !== null
                              ? "A"
                              : ""}
                            {item[columnMappings[selectedItem][1]]}
                          </span>

                          {item[columnMappings[selectedItem][2]] !== null &&
                          item[columnMappings[selectedItem][2]] !== "O" ? (
                            <img
                              src={check}
                              alt="check"
                              className="2xl:w-5 2xl:h-5 2xl:ml-2 h-3 w-3"
                            />
                          ) : (
                            <input
                              type="checkbox"
                              name="validate"
                              id={item.AA_REF || item.ANA_REF}
                              className="ml-3"
                              checked={selectedTimeForValidated.some(
                                (el) => el.AA_REF === item.AA_REF
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(item, e.target.checked)
                              }
                            />
                          )}
                          {item[columnMappings[selectedItem][3]] === 1 &&
                          item[columnMappings[selectedItem][3]] === 0 ? (
                            <img
                              src={exclamation}
                              alt="exclamation"
                              className="2xl:w-5 2xl:h-5 2xl:ml-2 h-3 w-3"
                            />
                          ) : (
                            <div className="2xl:w-5 2xl:h-5 2xl:ml-2 h-3 w-3"></div>
                          )}
                        </div>
                      ) : selectedItem === "Libellé Mission" ? (
                        item[columnMappings[selectedItem]]?.replace(/Â/g, "")
                      ) : (
                        item[columnMappings[selectedItem]]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {displayDiv ? <ManualValidationTime setRenderData={setRenderData}/> : null} 
           
          {totalPageCount > 1 ? (
            <div className="flex justify-center items-center w-full m-auto pt-2">
              <button
                disabled={currentPage <= 10}
                className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
                onClick={() => {
                  setCurrentPage(currentPage - 10);
                  setStartPage((prev) => Math.max(prev - 10, 1));
                  setEndPage((prev) => prev - 10);
                }}
              >
                Précédent
              </button>

              {startPage > 1 && <span>...</span>}

              {Array(totalPageCount)
                .fill(null)
                .slice(startPage - 1, endPage)
                .map((_, index) => (
                  <button
                    key={index + startPage}
                    className={`hover:bg-bc-lightOrange ${
                      currentPage === index + startPage ? "bg-bc-orange" : ""
                    } border-[1px] rounded-md border-bc-grey h-auto w-8 m-1`}
                    onClick={() => setCurrentPage(index + startPage)}
                  >
                    {index + startPage}
                  </button>
                ))}

              {endPage < totalPageCount && <span>...</span>}

              <button
                disabled={currentPage === totalPageCount}
                className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
                onClick={() => {
                  setCurrentPage(currentPage + 10);
                  setStartPage((prev) => prev + 10);
                  setEndPage((prev) => Math.min(prev + 10, totalPageCount));
                }}
              >
                Suivant
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ListView;
