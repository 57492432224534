import { useEffect } from "react";
import { useTabsStore } from "../../../store/Invoice/tabsStore";
import { useInvoiceStore } from "../../../store/Invoice/invoice";
import { getLevelRelaunchs } from "../../../utils/customerFile";

/**
 * SelectRelaunch
 * @description
 * Un composant qui permet de selectionner le niveau de relance
 * @date 13/02/2024
 * @author Iris Succi
 * @returns {JSX.Element} - The rendered component.
 */

const SelectRelaunch = () => {
  const { choiceTab } = useTabsStore();
  const { levelRelaunchs, setLevelRelaunchs, setSelectedRelaunch } =
    useInvoiceStore();

  useEffect(() => {
    choiceTab === "Relance" &&
      getLevelRelaunchs()
        .then((response) => setLevelRelaunchs(response?.data))
        .catch((error) => console.log(error));
  }, [choiceTab]);

  const handleChange = (event: any) => {
    setSelectedRelaunch(event.target.value);
  };

  return (
    <select
      onChange={handleChange}
      className="w-52 border-[1px] rounded border-gray-300 h-8 py-1 z-20 "
    >
      {levelRelaunchs?.map((item: any) => (
        <option key={item.MAT_ID} value={item.MAT_ID}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

export default SelectRelaunch;
