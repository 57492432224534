import { dataInvoicesFeelsStore } from "../../../store/Invoice/dataInvoicesFeelsStore";
import { useSearchBarStore } from "../../../store/utils/searchBar";
import { usePagination } from "../../../hooks/usePagination";
import { useEffect } from "react";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import { useLoadingStore } from "../../../store/utils/loading";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import { CreateCredit } from "../modal/Create/CreateCredit";
import { dateFormattedFr } from "../../../utils/TimeManager/timeUtils";
import plane from "../../../assets/icons/plane.png";
import VisuPdfModal from "../modal/VisuPdfModal";
/**

 * @description
* Ce composant affiche un tableau des crédits, permettant aux utilisateurs de les sélectionner et de les valider. Il gère également la pagination et la fonctionnalité de recherche. *
 * @param {object} credits - The list of credits to be displayed.
 * @param {object} creditsFilter - The filtered list of credits, based on the search query.
 * @param {function} setCreditsFilter - A function to set the filtered list of credits.
 * @param {function} handleCheckboxChange - A function to handle the change of individual checkboxes.
 * @param {number} currentPage - The current page of credits.
 * @param {function} setCurrentPage - A function to set the current page of credits.
 * @param {number} startPage - The starting page number of credits.
 * @param {function} setStartPage - A function to set the starting page number of credits.
 * @param {number} endPage - The ending page number of credits.
 * @param {number} totalPageCount - The total number of pages of credits.
 * @param {boolean} displayDiv - Whether or not the create crédit modal should be displayed.
 * @param {function} setSelectedDosRef - A function to set the selected dos reference for the create credit modal.
 * @returns {JSX.Element} - The rendered component.
 */
const CreditsTable = () => {
  const { credits, creditsFilter, setCreditsFilter } = dataInvoicesFeelsStore();
  const { search } = useSearchBarStore();
  const { isLoading } = useLoadingStore();
  const { displayDiv } = useCreateInvoiceStore();
  const {
    selectedIdsInvoices,
    setSelectedIdsInvoices,
    setSelectedDosRef,
    displayDivVisuPdf,
  } = useCreateInvoiceStore();
  const {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    totalPageCount,
  } = usePagination(creditsFilter, 30);

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e - The event object triggered by the checkbox click
   * @param {number} itemId - The id of the cash in flow item associated with the checkbox
   * @param {number} anaRef - The ana reference of the cash in flow item associated with the checkbox
   * @param {number} dosRef - The dos reference of the cash in flow item associated with the checkbox
   * @param {function} setSelectedIdsInvoices - A function to update the selected ids of invoices
   * @param {function} setSelectedDosRef - A function to update the selected dos reference
   * @description
   * This function handles the change of an individual checkbox in the cash in flow table. It checks if the checkbox is checked, and if so, it adds the corresponding item ID to the `selectedIdsInvoices` array. It also sets the `selectedDosRef` to the dos reference associated with the item. If the checkbox is unchecked, it removes the item ID from the `selectedIdsInvoices` array and sets the `selectedDosRef` to `null` if the item ID is no longer present in the array.
   */
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    anaRef: number,
    dosRef: number
  ) => {
    if (e.target.checked) {
      setSelectedIdsInvoices((prev) => [...prev, anaRef]);
      setSelectedDosRef(dosRef);
    } else {
      setSelectedIdsInvoices((prev) =>
        prev.filter((id) => id && id !== anaRef)
      );
      if (!selectedIdsInvoices.includes(anaRef)) {
        setSelectedDosRef(null);
      }
    }
  };

  useEffect(() => {
    const unvalidatedData = credits?.CreditsUnvalidated || [];
    let validatedData = credits?.CreditsValidated || [];

    validatedData = validatedData.map((item: any) => ({
      ...item,
      isValidated: true,
    }));

    let mergedData = [...unvalidatedData, ...validatedData];

    if (search !== "") {
      mergedData = mergedData.filter(
        (item) =>
          item["DOS_TITRE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["MIS_LIBELLE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["AOR_CODE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["ANA_CODELIB"]?.toLowerCase().includes(search.toLowerCase()) ||
          dateFormattedFr(item["ANA_DATEREALISATION"])
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item["ANA_EMTTTC"]
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    // Trier par date du plus récent au plus ancien (ANA_DATEREALISATION)
    mergedData.sort((a, b) => {
      const dateA = new Date(a.ANA_DATEREALISATION).getTime();
      const dateB = new Date(b.ANA_DATEREALISATION).getTime();
      return dateB - dateA; // Triez du plus récent au plus ancien
    });

    setCreditsFilter(mergedData);
  }, [search, credits]);

  return (
    <>
      <div className="relative border-[1px] rounded-b-md w-full h-full overflow-x-hidden">
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
            <CircleLoarder />
          </div>
        ) : credits.length > 0 ? (
          <div className="border-[1px] rounded-b-md w-full h-full overflow-y-scroll">
            <table className="w-full">
              <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0">
                <tr className="w-[5%] border-r-[1px] flex justify-start items-center">
                  {" "}
                </tr>
                <tr className="w-[10%] border-r-[1px] p-2">N° avoir</tr>
                <tr className="w-[15%] border-r-[1px] p-2">Dossier</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Mission</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Origine</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Libellé</tr>
                <tr className="w-[5%] border-r-[1px] p-2">H.T</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Tx T.V.A</tr>
                <tr className="w-[5%] border-r-[1px] p-2">T.V.A</tr>
                <tr className="w-[5%] border-r-[1px] p-2">N.S</tr>
                <tr className="w-[5%] border-r-[1px] p-2">T.T.C</tr>
                <tr className="w-[15%] border-r-[1px] p-2">
                  Date de décaissement
                </tr>
              </thead>
              <tbody className="flex flex-col w-full text-xs">
                {filteredData?.map((credit, index) => (
                  <tr
                    key={index}
                    className={`w-full border-b-[1px] flex ${
                      credit.isValidated ? "bg-bc-green-pale" : 
                      index % 2 === 0 ? "bg-grey-Array" : ""
                    }`}
                  >
                    <td className="w-[5%] border-r-[1px] flex justify-start items-center">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="mx-1"
                        value={credit.id}
                        checked={selectedIdsInvoices.includes(credit.ANA_REF)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            credit.ANA_REF,
                            credit.DOS_REF
                          )
                        }
                      />
                      <td className="pb-1">
                        {credit?.isSent ? (
                          <img src={plane} alt="plane" className="w-4 h-4" />
                        ) : (
                          ""
                        )}
                      </td>
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {credit?.ANA_FACTURE}
                    </td>
                    <td className="w-[15%] border-r-[1px] p-2">
                      {credit?.DOS_TITRE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {credit?.MIS_LIBELLE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {credit?.AOR_CODE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {credit?.ANA_CODELIB}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {credit?.ANA_EMTHT}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {credit?.TVA_VALEUR} %
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {credit?.ANA_EMTTVA}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2"></td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {credit?.ANA_EMTTTC}
                    </td>
                    <td className="w-[15%] border-r-[1px] p-2">
                      {dateFormattedFr(credit?.ANA_DATEREALISATION)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : <p className="text-center w-full mt-5 italic">Aucun avoir</p>}
        {displayDiv ? <CreateCredit /> : null}
        {displayDivVisuPdf ? <VisuPdfModal /> : null}
      </div>
      <>
        {totalPageCount > 1 ? (
          <div className="flex justify-center items-center w-full m-auto pt-2">
            <button
              disabled={currentPage <= 10}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage - 10);
                setStartPage((prev) => Math.max(prev - 10, 1));
                setEndPage((prev) => prev - 10);
              }}
            >
              Précédent
            </button>

            {startPage > 1 && <span>...</span>}

            {Array(totalPageCount)
              .fill(null)
              .slice(startPage - 1, endPage)
              .map((_, index) => (
                <button
                  key={index + startPage}
                  className={`hover:bg-bc-lightOrange ${
                    currentPage === index + startPage ? "bg-bc-orange" : ""
                  } border-[1px] rounded-md border-bc-grey h-auto w-8 m-1`}
                  onClick={() => setCurrentPage(index + startPage)}
                >
                  {index + startPage}
                </button>
              ))}

            {endPage < totalPageCount && <span>...</span>}

            <button
              disabled={currentPage === totalPageCount}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage + 10);
                setStartPage((prev) => prev + 10);
                setEndPage((prev) => Math.min(prev + 10, totalPageCount));
              }}
            >
              Suivant
            </button>
          </div>
        ) : null}
      </>
    </>
  );
};

export default CreditsTable;
