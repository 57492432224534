/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getClasseurs } from "../../../../utils/folders";
import ClasseursTree from "./ClasseursTree";
import SearchBar from "../../../Utils/SearchBar";
import CreateClientFile from "../../buttons/CreateClientFile";
import { useExplorer } from "../../../../store/Crm/Explorer";

/**
 * @component
 * @name Explorer
 * @description A component that displays a list of classeurs and allows users to create new client files and search for existing clients.
 * @author Iris Succi
 * @date 2023
 */
const Explorer = () => {
  const { classeurs, setClasseurs } = useExplorer();
  const order = [
    "1-Clients",
    "2-Prospects",
    "3-Administration Interne",
    "4-Résiliés",
    "{*}Archives",
  ];
  // L'état pour garder la trace de l'onglet ouvert
  const [openClasseurId, setOpenClasseurId] = useState(null);

  const handleToggleClasseur = (classeurId: any) => {
    if (openClasseurId === classeurId) {
      // Si le même onglet est cliqué, fermez-le
      setOpenClasseurId(null);
    } else {
      // Sinon, ouvrez le nouvel onglet
      setOpenClasseurId(classeurId);
    }
  };

  /**
   * @function
   * @name useEffect
   * @description Fetches the list of classeurs from the server and filters and sorts the results by PDOS_NOM.
   * @author Iris Succi
   * @date 2023
   */
  useEffect(() => {
    getClasseurs()
      .then((res) => {
        // Filter elements that do not contain "{*}"
        const filteredClasseurs = res.data.filter(
          (classeur: any) => !classeur.PDOS_NOM.includes("{*}")
        );

        // Sort filtered elements
        const sortedClasseurs = filteredClasseurs.sort((a: any, b: any) => {
          return order.indexOf(a.PDOS_NOM) - order.indexOf(b.PDOS_NOM);
        });

        setClasseurs(sortedClasseurs);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="flex flex-col w-[98%] h-full overflow-y-scroll border-[1px] rounded-md mt-2 pt-2 bg-bc-lightGrey  items-center">
      {openClasseurId !== null ? (
        <div className="w-full ">
          <SearchBar />
        </div>
      ) : null}
      <CreateClientFile />
      <hr className="my-2 w-6/12 m-auto" />
      {classeurs.map((classeur) => (
        <ClasseursTree
          key={classeur.PDOS_ID}
          classeur={classeur}
          isOpen={openClasseurId === classeur.PDOS_ID}
          onToggle={() => handleToggleClasseur(classeur.PDOS_ID)}
        />
      ))}
    </div>
  );
};

export default Explorer;
