import Select from "react-select";
import { getClasseurs } from "../../../utils/folders";
import { useEffect } from "react";
import { useClientFileStore } from "../../../store/FinancialStatement/inputStore";

/**
 * @component
 * @name BinderSelect
 * @description
  * Un composant qui affiche une liste déroulante de sélection pour sélectionner un dossier client
  * classeur. Le composant récupère la liste des classeurs à partir de l'API et les trie
  * en fonction du tableau `order` fourni.
 *
 * @author Iris Succi
 * @date 2023
 */
const BinderSelect = () => {
  const { binders, setBinders, setSelectedBinder, selectedClientFile } =
    useClientFileStore();

  const order = [
    "1-Clients",
    "2-Prospects",
    "3-Administration Interne",
    "4-Résiliés",
    "{*}Archives",
  ];

  useEffect(() => {
    getClasseurs()
      .then((res) => {
        const sortedClasseurs = res.data.sort((a: any, b: any) => {
          return order.indexOf(a.PDOS_NOM) - order.indexOf(b.PDOS_NOM);
        });
        const formattedFiles = sortedClasseurs.map((classeur: any) => ({
          value: classeur.PDOS_ID,
          label: classeur.PDOS_NOM,
        }));
        setBinders(formattedFiles);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Select
      options={binders}
      isMulti
      className="w-full rounded-sm mt-2 p-1 font-thin"
      isDisabled={selectedClientFile !== 0}
      onChange={(selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setSelectedBinder(selectedValues);
      }}
    />
  );
};

export default BinderSelect;
