import { useClientFileStore } from "../../../store/Crm/ClientFile";
import { useExplorer } from "../../../store/Crm/Explorer";
import { updateParagraphes } from "../../../utils/customerFile";
import { errorFetch } from "../../../utils/toaster";
import { useState, useEffect } from 'react';

const Paragraphes = () => {
  const {
    openParagraphes,
    setOpenParagraphes,
    paragraphes = [],
  } = useClientFileStore();
  const { dosRef } = useExplorer();

  const [modifiedParagraphes, setModifiedParagraphes] = useState<{
    [key: string]: string;
  }>({});

  // Initialise modifiedParagraphes avec les valeurs initiales de paragraphes
  useEffect(() => {
    const initialModifiedParagraphes: { [key: string]: string } = {};

    paragraphes?.forEach((para: any, index: number) => {
      const key = `PAR_${(index + 1).toString().padStart(2, '0')}_TEXTE`;
      initialModifiedParagraphes[key] = para.PAR_TEXTE;
    });

    setModifiedParagraphes(initialModifiedParagraphes);
  }, [paragraphes]);

  const handleUpdateParagraphe = () => {
    updateParagraphes(dosRef, modifiedParagraphes)
      .then((res) => {
        console.log(res);
        setOpenParagraphes(false)
      })
      .catch((err) => {
        console.log(err);
        errorFetch();
      });
  };

  const handleChange = (index: number, value: string) => {
    const key = `PAR_${(index + 1).toString().padStart(2, '0')}_TEXTE`; // Génère une clé comme "PAR_01_TEXTE"

    setModifiedParagraphes((prev) => ({
      ...prev,
      [key]: value, // Met à jour avec la nouvelle valeur
    }));
  };

  return (
    openParagraphes && (
      <>
        <div className="justify-center items-center flex overflow-hidden overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none min-w-full ">
          <div className="relative w-auto md:w-1/4 my-6 max-w-3xl min-w-[45%] h-2/3">
            {/* Content */}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full">
              {/* Header */}
              <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t w-full">
                <h3 className="text-base md:text-3xl font-semibold text-center">
                  Paragraphes
                </h3>
              </div>
              {/* Body */}
              <div className="text-sm md:text-base p-5 overflow-y-scroll">
                {Array.isArray(paragraphes) && paragraphes?.map((para: any, index: number) => {
                  const key = `PAR_${(index + 1).toString().padStart(2, '0')}_TEXTE`;
                  const value = modifiedParagraphes[key] || para.PAR_TEXTE;

                  return (
                    <div
                      className="w-11/12 m-auto flex flex-col justify-start items-start my-2"
                      key={index}
                    >
                      <p>{para.name}</p>
                      <textarea
                        name={para.name}
                        id={para.name}
                        value={value}
                        disabled={para.PAR_EDITABLE === 0}
                        required={para.PAR_REQUIRED === 1}
                        onChange={(e) => handleChange(index, e.target.value)}
                        className="w-full border-[1px] rounded-sm p-2 h-28"
                      />
                    </div>
                  );
                })}
                {!Array.isArray(paragraphes) && <p>Aucun paragraphe disponible.</p>}
              </div>
              {/* Footer */}
              <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setOpenParagraphes(false)}
                >
                  Annuler
                </button>
                <button
                  className="bg-bc-green text-white active:bg-bc-green font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleUpdateParagraphe}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default Paragraphes;
