import { useTabsStore } from "../../../store/Invoice/tabsStore";

/**
 * @component
 * @name Tabs
 * @description
 * Un composant qui restitue un ensemble d'onglets permettant de basculer entre différents types de paiement.
 *
 * @param {object} choiceTab - The current active tab.
 * @param {function} setChoiceTab - A function to set the active tab.
 */
const Tabs = () => {
  const { choiceTab, setChoiceTab } = useTabsStore();

  return (
    <div className="flex justify-start items-start border-[1px] rounded-t-md w-[1000px] mt-5">
      <button
        className={
          choiceTab !== "Acompte"
            ? "border-r-[1px] rounded-tl-md  py-1 w-[100px]"
            : "border-r-[1px] rounded-tl-md  py-1 w-[100px] bg-[#cecece]"
        }
        onClick={() => setChoiceTab("Acompte")}
      >
        Acomptes
      </button>
      <button
        className={
          choiceTab !== "Provision"
            ? "border-r-[1px]  py-1 w-[100px]"
            : "border-r-[1px]  py-1 w-[100px] bg-[#cecece]"
        }
        onClick={() => setChoiceTab("Provision")}
      >
        Provisions
      </button>
      <button
        className={
          choiceTab !== "Honoraire"
            ? " py-1 w-[200px] border-r-[1px]"
            : " py-1 w-[200px] bg-[#cecece] border-r-[1px]"
        }
        onClick={() => setChoiceTab("Honoraire")}
      >
        Elements facturables
      </button>
      <button
        className={
          choiceTab !== "Proformat"
            ? "py-1 w-[100px] border-r-[1px]"
            : "py-1 bg-[#cecece] w-[100px] border-r-[1px]"
        }
        onClick={() => setChoiceTab("Proformat")}
      >
        Proformas
      </button>
      <button
        className={
          choiceTab !== "Facture"
            ? "py-1 border-r-[1px] w-[100px]"
            : "py-1 bg-[#cecece] border-r-[1px] w-[100px]"
        }
        onClick={() => setChoiceTab("Facture")}
      >
        Factures
      </button>
      <button
        className={
          choiceTab !== "Avoir"
            ? "py-1 w-[100px] border-r-[1px]"
            : "py-1 w-[100px] bg-[#cecece] border-r-[1px]"
        }
        onClick={() => setChoiceTab("Avoir")}
      >
        Avoirs
      </button>
      <button
        className={
          choiceTab !== "Encaissement"
            ? "py-1 w-[150px] border-r-[1px]"
            : "py-1 w-[150px] bg-[#cecece] border-r-[1px]"
        }
        onClick={() => setChoiceTab("Encaissement")}
      >
        Encaissement
      </button>
      <button
        className={
          choiceTab !== "Bordereau"
            ? "py-1 w-[120px] border-r-[1px]"
            : "py-1 w-[120px] bg-[#cecece] border-r-[1px]"
        }
        onClick={() => setChoiceTab("Bordereau")}
      >
        Bordereau
      </button>
      <button
        className={
          choiceTab !== "Relance"
            ? "py-1 w-[120px]"
            : "py-1 w-[120px] bg-[#cecece]"
        }
        onClick={() => setChoiceTab("Relance")}
      >
        Relance
      </button>
    </div>
  );
};

export default Tabs;
