import React, { useEffect } from "react";
import { useDateExerciceStore } from "../../../store/FinancialStatement/dateExerciceStore";
import { getFinancialExercices } from "../../../utils/FinancialStatement/state";
import { useTabsFSStore } from "../../../store/FinancialStatement/tabsStore";

interface DateExerciceProps {
  type: "du" | "au";
}

type ExerciceRefMap = {
  [key: string]: number;
};

/**
 * @component
 * @name DateExercice
 * @description
  * Un composant qui affiche une liste déroulante de sélection pour sélectionner la date de début
  * ou de fin d'une période. Le composant est utilisé à la fois dans les
  * rapports de facturation et de production.
 * @param {string} type - The type of date to select (`du` or `au`).
 * @param {object} du - The current start date.
 * @param {object} au - The current end date.
 * @param {Function} setDu - A function to set the current start date.
 * @param {Function} setAu - A function to set the current end date.
 * @param {Array<object>} financialExercises - The list of financial
 *    exercises.
 * @param {Function} setFinancialExercises - A function to set the financial
 *    exercises.
 * @author Iris Succi
 * @date 2023
 */
const DateExercice: React.FC<DateExerciceProps> = ({ type }) => {
  const { billingTypeSelected, productionTypeSelected } = useTabsFSStore();
  const { du, au, setDu, setAu, financialExercises, setFinancialExercises } =
    useDateExerciceStore();
  const current = type === "du" ? du : au;
  const setFunction = type === "du" ? setDu : setAu;

  useEffect(() => {
    getFinancialExercices()
      .then((res) => {
        setFinancialExercises(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const exerciceRefMap: ExerciceRefMap = financialExercises.reduce(
    (acc: ExerciceRefMap, fe) => {
      acc[fe.AEX_INTITULE] = fe.AEX_REF;
      return acc;
    },
    {}
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    field: "exercice" | "mois"
  ) => {
    const exerciceValue =
      field === "exercice" ? e.target.value : current.exercice;
    const moisValue = field === "mois" ? e.target.value : current.mois;
    const aexRefValue = exerciceRefMap[exerciceValue] || current.aex_ref;
    const exerciceAnnee =
      financialExercises.find((fe) => fe.AEX_INTITULE === exerciceValue)
        ?.AEX_ANNEE || current.aex_annee;

    setFunction(exerciceValue, moisValue, aexRefValue, exerciceAnnee);
  };

  const uniqueExercices = Array.from(
    new Set(financialExercises.map((fe) => fe.AEX_INTITULE))
  ).sort((a, b) => a.localeCompare(b));

  const filteredMonths = financialExercises
    .filter((fe) => fe.AEX_INTITULE === current.exercice)
    .map((fe) => fe.AEX_LIB_MOIS_LONG + " " + fe.AEX_ANNEE);

  return (
    <div className="w-full mr-2 flex justify-between items-center">
      <select
        value={current.exercice}
        onChange={(e: any) => handleChange(e, "exercice")}
        className={`border-[1px] h-9 rounded-sm mt-2 p-1 font-thin w-1/2 mr-2 ${
          type === "au" &&
          (billingTypeSelected ===
            "Répartion du chiffre d’affaire par dossier" ||
            productionTypeSelected === "Budgets comparés" ||
            billingTypeSelected === "Statistiques par activité")
            ? "bg-gray-200"
            : ""
        }`}
        disabled={
          type === "au" &&
          (billingTypeSelected ===
            "Répartion du chiffre d’affaire par dossier" ||
            productionTypeSelected === "Budgets comparés" ||
            billingTypeSelected === "Statistiques par activité")
        }
      >
        <option value=""></option>
        {uniqueExercices.map((exercice) => (
          <option key={exercice} value={exercice}>
            {exercice}
          </option>
        ))}
      </select>
      <select
        value={current.mois}
        onChange={(e: any) => handleChange(e, "mois")}
        className={`border-[1px] h-9 rounded-sm mt-2 p-1 font-thin w-1/2 ${
          productionTypeSelected === "Feuille des temps collab/client" ||
          billingTypeSelected ===
            "Répartion du chiffre d’affaire par dossier" ||
          productionTypeSelected === "Budgets comparés"
            ? "bg-gray-200"
            : ""
        } ${
          type === "au" && billingTypeSelected === "Statistiques par activité"
            ? "bg-gray-200"
            : ""
        }`}
        disabled={
          productionTypeSelected === "Feuille des temps collab/client" ||
          (type === "au" && billingTypeSelected === "Statistiques par activité")
        }
      >
        <option value=""></option>
        {filteredMonths.map((mois) => (
          <option key={mois} value={mois}>
            {mois}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DateExercice;
