import { create } from "zustand";

interface MyState {
    selectedIdsInvoices: any[];
    setSelectedIdsInvoices: (update: any[] | ((prev: any[]) => any[])) => void;

    selectedDosRef: any;
    setSelectedDosRef: (selectedDosRef: any) => void;

    selectedMisRef: any;
    setSelectedMisRef: (selectedMisRef: any) => void;

    selectedMisName: any;
    setSelectedMisName: (selectedMisName: any) => void;

    animationClass: string;
    setAnimationClass: (animationClass: string) => void;

    displayDiv: boolean;
    setDisplayDiv: (displayDiv: boolean) => void;

    // CreateInvoice
    clientFiles: any[];
    setClientFiles: (clientFile: any[]) => void;

    selectedFile: number;
    setSelectedFile: (selectedFile: number) => void;

    startDate: Date;
    setStartDate: (startDate: Date) => void;

    feesSummary: any;
    setFeesSummary: (feesSummary: any) => void;

    contacts: any[]
    setContacts: (contacts: any[]) => void;

    selectedContact: any;
    setSelectedContact: (selectedContact: any) => void;

    masks: any[];
    setMasks: (masks: any[]) => void;

    selectedMask: any;
    setSelectedMask: (selectedMask: any) => void;

    displayDivFee: boolean;
    setDisplayDivFee: (displayDivFee: boolean) => void;

    displayDivVisuPdf: boolean;
    setDisplayDivVisuPdf: (displayDivVisuPdf: boolean) => void;

    displayDivSchedule: boolean;
    setDisplayDivSchedule: (displayDivSchedule: boolean) => void;

    displayPointOutAcompteModal: boolean;
    setDisplayPointOutAcompteModal: (displayPointOutAcompteModal: boolean) => void;

    selectedIdPrepayment: number;
    setSelectedIdPrepayment: (selectedIdPrepayment: number) => void;

    isPointedOut: boolean;
    setIsPointedOut: (isPointedOut: boolean) => void;
  }
  
  export const useCreateInvoiceStore = create<MyState>((set) => ({
    selectedIdsInvoices: [],
    setSelectedIdsInvoices: (update: any[] | ((prev: any[]) => any[])) =>
      typeof update === 'function' ? set((state) => ({ selectedIdsInvoices: update(state.selectedIdsInvoices) }))
                                   : set({ selectedIdsInvoices: update }),

    selectedDosRef: 0,
    setSelectedDosRef: (selectedDosRef: any) => set({ selectedDosRef }),

    selectedMisRef: 0,
    setSelectedMisRef: (selectedMisRef: any) => set({ selectedMisRef }),

    selectedMisName: '',
    setSelectedMisName: (selectedMisName: any) => set({ selectedMisName }),
    
    animationClass: '',
    setAnimationClass: (animationClass: string) => set({ animationClass }),

    displayDiv: false,
    setDisplayDiv: (displayDiv: boolean) => set({ displayDiv }),

    displayDivFee: false,
    setDisplayDivFee: (displayDivFee: boolean) => set({ displayDivFee }),

    // CreateInvoice
    clientFiles: [],
    setClientFiles: (clientFiles: any[]) => set({ clientFiles }),

    selectedFile: 0,
    setSelectedFile: (selectedFile: number) => set({ selectedFile }),

    startDate: new Date(),
    setStartDate: (startDate: Date) => set({ startDate }),

    feesSummary: {},
    setFeesSummary: (feesSummary: any) => set({ feesSummary }),

    contacts: [],
    setContacts: (contacts: any[]) => set({ contacts }),

    selectedContact: {},
    setSelectedContact: (selectedContact: any) => set({ selectedContact }),

    masks: [],
    setMasks: (masks: any[]) => set({ masks }),

    selectedMask: {},
    setSelectedMask: (selectedMask: any) => set({ selectedMask }),

    displayDivVisuPdf: false,
    setDisplayDivVisuPdf: (displayDivVisuPdf: boolean) => set({ displayDivVisuPdf }),

    displayDivSchedule: false,
    setDisplayDivSchedule: (displayDivSchedule: boolean) => set({ displayDivSchedule }),

    displayPointOutAcompteModal: false,
    setDisplayPointOutAcompteModal: (displayPointOutAcompteModal: boolean) => set({ displayPointOutAcompteModal }),

    selectedIdPrepayment: 0,
    setSelectedIdPrepayment: (selectedIdPrepayment: number) => set({ selectedIdPrepayment }),

    isPointedOut: false,
    setIsPointedOut: (isPointedOut: boolean) => set({ isPointedOut }),
  }));
  