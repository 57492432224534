import { useNavigate } from "react-router-dom";
import dataMenu from "../../data/dataMenu";
import { useTranslation } from "react-i18next";
import {
  getRightsInvoices,
  getRightsState,
  getRightsTime,
} from "../../utils/user";
import { useRightTimeContext } from "../../context/RightTimeContext";
import "../../css/animations.css";
import { useRightContext } from "../../context/RightContext";
import React from "react";
import { useRightInvoiceContext } from "../../context/RightInvoiceContext";
import { useRightStateContext } from "../../context/RightStateContext";

/**
 * @component
 * @name Card
 * @description
  * Un composant qui restitue une carte pour afficher un élément de rapport.
 * @param {object[]} dataMenu - The list of report items.
 * @param {Function} navigate - A function to handle navigation to the report page.
 * @param {object} rights - The user's permissions.
 * @param {Function} setEditLimit - A function to set the edit limit.
 * @param {Function} setVisuLimit - A function to set the visualisation limit.
 * @param {Function} setTimeEntry - A function to set the time entry permission.
 * @param {Function} setDeleteTime - A function to set the time deletion permission.
 * @author Iris Succi
 * @date 2023
 */
const Card = () => {
  // Translation
  const { t } = useTranslation();

  //Hook
  const navigate = useNavigate();

  //Context
  const { buroFact, buroTime, chrono, easyFile, etats, analyse, cost } =
    useRightContext();
  const {
    setEditLimit,
    setVisuLimit,
    setTimeEntry,
    setDeleteTime,
    setValidatedTime,
  } = useRightTimeContext();
  const {
    setAcompteRight,
    setAvoirRight,
    setBordereauxRight,
    setProvisionRight,
    setDeboursExtRight,
    setDeboursIntRight,
    setEncaissementRight,
    setFactureRight,
    setHonorairesRight,
    setRelanceRight,
    setWithoutMissionRight,
    setMandatoryMissionRight,
  } = useRightInvoiceContext();
  const {
    setEncaissementStateRight,
    setFacturationStateRight,
    setProductionStateRight,
  } = useRightStateContext();

  const rights: { [key: string]: number } = {
    BuroFact: buroFact,
    BuroTime: buroTime,
    "Chrono des documents": chrono,
    EasyFile: easyFile,
    Etats: etats,
    Analyse: analyse,
    "Notes de frais": cost,
  };

  /**
   * @function
   * @name handleCardClick
   * @description
   * A function that handles the click event of a card.
   *
   * @param {object} item - The report item that was clicked.
   * @author Iris Succi
   * @date 2023
   */

  const handleCardClick = async (item: any) => {
    if (item.right === "BuroTime") {
      try {
        const response = await getRightsTime();
        localStorage.setItem("rightsTime", JSON.stringify(response.data));
        setEditLimit(
          response.data["Edition limitée aux temps de l'acteur en cours"]
        );
        setVisuLimit(
          response.data["Visualisation limitée aux temps de l'acteur en cours"]
        );
        setTimeEntry(response.data["Saisie temps"]);
        setDeleteTime(response.data["Suppression des temps interdite"]);
        setValidatedTime(response.data["Validation temps"]);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    } else if (item.right === "BuroFact") {
      try {
        const response = await getRightsInvoices();
        localStorage.setItem("rightsInvoice", JSON.stringify(response.data));
        setAcompteRight(response.data["Acomptes Clients"]);
        setAvoirRight(response.data["Avoirs sur factures"]);
        setBordereauxRight(response.data["Bordereaux"]);
        setProvisionRight(response.data["Demandes de provision"]);
        setDeboursExtRight(response.data["Débours externes"]);
        setDeboursIntRight(response.data["Débours internes"]);
        setEncaissementRight(response.data["Encaissements"]);
        setFactureRight(response.data["Factures"]);
        setHonorairesRight(response.data["Honoraires"]);
        setRelanceRight(response.data["Relances"]);
        setWithoutMissionRight(response.data["Interdiction de saisie de missions"]);
        setMandatoryMissionRight(response.data["Missions obligatoires"]);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    } else if (item.right === "Etats") {
      const response = await getRightsState();
      localStorage.setItem("rightsState", JSON.stringify(response.data));
      setEncaissementStateRight(response.data["Etats des encaissements"]);
      setFacturationStateRight(response.data["Etats de facturation"]);
      setProductionStateRight(response.data["Etats de production"]);
    }
    navigate(item.path);
  };

  return (
    <div className="grid grid-cols-3 w-full h-full 2xl:gap-5 gap-3 ">
      {dataMenu?.map(
        (item) =>
          rights[item.right] === 1 && (
            <div
              className="border-slate-600 border-[1px] rounded-md shadow-md w-full h-full m-auto bg-white"
              key={item.id}
            >
              <button
                onClick={() => handleCardClick(item)}
                className="w-full h-full rounded-md relative"
              >
                <div className="flex flex-col justify-start items-start w-full h-full">
                  <h2 className="font-extrabold 2xl:text-2xl text-lg pt-5 2xl:ml-5 ml-1">
                    {t(item.title)}
                  </h2>
                  <div className="flex w-full h-2">
                    <div className="w-11/12 2xl:ml-5 ml-1 border-4 border-bc-orange bg-bc-orange"></div>
                    <div className="2xl:w-8 2xl:h-8 w-6 h-6 rounded-full bg-bc-rose absolute right-2 top-10 "></div>
                    <div className="2xl:w-6 2xl:h-6 w-4 h-4 rounded-full bg-slate-200 absolute 2xl:right-8 right-6 top-11"></div>
                  </div>
                  <div className="mt-2 2xl:mt-4 grid grid-cols-3 w-11/12 m-auto h-full">
                    <div className="xl:col-start-1 xl:col-end-2 h-full hidden xl:flex justify-center items-center mb-2">
                      <div className="border-[1px] border-bc-orange rounded-full p-2 w-auto h-auto mr-4">
                        <img
                          src={item.logo}
                          alt={item.title}
                          className="w-auto h-auto m-auto"
                        />
                      </div>
                    </div>
                    <div className="xl:col-start-2 xl:col-end-4 col-start-1 col-end-4 h-full text-start text-sm xl:text-lg flex justify-center items-center">
                      {item.description.split(",").map((part, index, array) => (
                        <React.Fragment key={index}>
                          {part}
                          {index < array.length - 1 ? <br /> : ""}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </button>
            </div>
          )
      )}
    </div>
  );
};

export default Card;
