import { create } from "zustand"    

type createSchedule = {
   modeSchedule: string;
   setModeSchedule:  (modeSchedule: string) => void;

   modePaiement: any;
   setModePaiement: (modePaiement: any) => void;

   selectedModePaiement: any;
   setSelectedModePaiement: (selectedModePaiement: any) => void;

   banks: any;
   setBanks: (banks: any) => void;

   selectedBank: string;
   setSelectedBank: (selectedBank: string) => void;

   selectedFrequencies: string;
   setSelectedFrequencies: (selectedFrequencies: string) => void;
   
   selectedDay: string;
   setSelectedDay: (selectedDay: string) => void;

   nbSchedule: string;
   setNbSchedule: (nbSchedule: string) => void;

   firstDay: Date;
   setFirstDay : (firstDay : Date) => void;

    solde: string;
    setSolde: ( solde: string) => void;

    totalSchedule: string;
    setTotalSchedule: ( totalSchedule: string) => void;

    schedules: any;
    setSchedules: (schedules: any) => void;

    ANA_REF: number;
    setANA_REF: ( ANA_REF: number) => void;

    domiciliations: any;
    setDomiciliations: (domiciliations: any) => void;

    selectedDomiciliation: any;
    setSelectedDomiciliation: (selectedDomiciliation: any) => void;

    reset:() => void;

    idSchedule: number | null;
    setIdSchedule: (idSchedule: number | null) => void;

    dateSchedule: any;
    setDateSchedule: (dateSchedule: any) => void;

    amountSchedule: number;
    setAmoutSchedule: (amountSchedule: number) => void;

    dateBordereau: any;
    setDateBordereau: (dateBordereau: any) => void;

    update: boolean;
    setUpdate: (update: boolean) => void;
};

export const useCreateSchedule = create<createSchedule>((set) => ({
    modeSchedule: "",
    setModeSchedule: (modeSchedule) => set({ modeSchedule }),

    modePaiement: [],
    setModePaiement: (modePaiement: any) => set({ modePaiement }),

    selectedModePaiement: {
        value: "" ,
        label: "",
    },
    setSelectedModePaiement: (selectedModePaiement) => set({selectedModePaiement}),

    banks: [],
    setBanks: (banks: any) => set({banks }),

    selectedBank: "",
    setSelectedBank : (selectedBank) => set({selectedBank}),

    selectedFrequencies: "",
    setSelectedFrequencies: (selectedFrequencies) => set({selectedFrequencies}),

    selectedDay: "",
    setSelectedDay: (selectedDay) => set({selectedDay}),

    nbSchedule: "",
    setNbSchedule: (nbSchedule) => set({nbSchedule}),

    firstDay: new Date(),
    setFirstDay: (firstDay) => set({firstDay}),

    solde: "",
    setSolde: (solde) => set({solde}),

    totalSchedule: "",
    setTotalSchedule: (totalSchedule) => set({totalSchedule}),

    schedules : [],
    setSchedules: (schedules) => set({schedules}),

    ANA_REF: 0,
    setANA_REF: (ANA_REF) => set({ANA_REF}),

    domiciliations: [],
    setDomiciliations: (domiciliations) => set({domiciliations}),

    selectedDomiciliation: "",
    setSelectedDomiciliation: (selectedDomiciliation) => set({selectedDomiciliation}),

    reset: () => set({
        modeSchedule: "",
        selectedFrequencies: "",
        selectedBank: "",
        selectedDay: "",
        nbSchedule: "",
        selectedDomiciliation: "",
        selectedModePaiement:{value: "", label:""},
        dateBordereau: null,
        dateSchedule: null
    }),

    idSchedule: null,
    setIdSchedule: (idSchedule) => set({idSchedule}),

    dateSchedule: null,
    setDateSchedule: (dateSchedule) => set({dateSchedule}),

    amountSchedule: 0,
    setAmoutSchedule: (amountSchedule) => set({amountSchedule}),

    dateBordereau: null,
    setDateBordereau: (dateBordereau) => set({dateBordereau}),

    update: false,
    setUpdate: (update) => set({update})
}))