import axios from "axios";
import { axiosWithToken } from "./axiosService";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get folder
 */

export const getClasseurs = () => {
    try {
        const response = axiosWithToken().get(`api/folder/classeurs`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getDossiers = (PDOS_ID: number) => {
    try {
        const response = axiosWithToken().get(`/api/folder/classeurs/${PDOS_ID}/dossiers`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getAllDossiers = () => {
    try {
        const response = axiosWithToken().get(`api/folder/dossiers`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getDossiersDatas = (DOS_REF: number) => {
    try {
        const response = axiosWithToken().get(`api/folder/dossiers/${DOS_REF}/datas`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getFolderClasseursTemplateCode = (PDOS_ID: number) => {
    try {
        const response = axiosWithToken().get(`api/folder/classeurs/${PDOS_ID}/templatecode`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
};

export const getVarFiche = (PDOS_ID: number, PENT_ID: number) => {
    try {
        const response = axiosWithToken().get(`/api/folder/classeurs/${PDOS_ID}/varfile?PENT_ID=${PENT_ID}`)
        return response
    } 
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}
