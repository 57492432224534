import { useRightStateContext } from "../../../context/RightStateContext";
import { monthMap } from "../../../data/dataInvoice";
import { useDateExerciceStore } from "../../../store/FinancialStatement/dateExerciceStore";
import { useTabsFSStore } from "../../../store/FinancialStatement/tabsStore";
import { useLoadingStore } from "../../../store/utils/loading";
import {
  getStateSalesBreakdown,
  getStateStatisticByActivity,
} from "../../../utils/FinancialStatement/state";
import { convertToServerDateFormat } from "../../../utils/functionService";
import { errorFetch } from "../../../utils/toaster";
import BinderSelect from "../select/BinderSelect";
import ClientFileSelect from "../select/ClientFileSelect";
import DateExercice from "../select/ExerciceSelect";

/**
 * @component
 * @name Billing
 * @description
* Un composant qui génère un rapport PDF pour le type de facturation sélectionné.
* Les types de facturation sont « Répartition du chiffre d’affaire »,
* « Statistiques par activité » et « Journal de facturation client ».
*
* Le composant permet également aux utilisateurs de filtrer le rapport par plage de dates.
 * @author Iris Succi
 * @date 2023
 */
const Billing = () => {
  const { setIsLoading } = useLoadingStore();
  const {
    billingTypes,
    billingTypeSelected,
    setBillingTypeSelected,
    setDocumentUrl,
    increment,
  } = useTabsFSStore();
  const { du, au } = useDateExerciceStore();

   //Context
   const { facturationStateRight } = useRightStateContext();

  /**
   * Convertit les dates « du » et « au » au format du serveur.
   *
   * @param {Date} du - The starting date
   * @param {Date} au - The ending date
   * @returns {string} - The formatted ending date
   * @author Iris Succi
   * @date 2023
   */
  const startDate = convertToServerDateFormat(du) + "-01";
  const endDate = new Date(convertToServerDateFormat(au));
  endDate.setMonth(endDate.getMonth() + 1);
  endDate.setDate(0);

  const formattedEndDate = `${endDate.getFullYear()}-${String(
    endDate.getMonth() + 1
  ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;

  /**
   * Génère le rapport PDF pour le type de facturation sélectionné.
   *
   * @param {object} du - The starting date
   * @param {object} au - The ending date
   * @author Iris Succi
   * @date 2023
   */
  const handlePdfBilling = () => {
    setIsLoading(true);
    increment();

    const monthName = du.mois.split(" ")[0];
    const monthNumber = monthMap[monthName];

    if (billingTypeSelected === "Répartition du chiffre d’affaire") {
      getStateSalesBreakdown(startDate, formattedEndDate)
        .then((res) => {
          // Création de l url
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
        })
        .catch((err) => {
          setIsLoading(false);
          errorFetch();
          console.log(err)
        });
    } else if (billingTypeSelected === "Statistiques par activité") {
      getStateStatisticByActivity(startDate, monthNumber)
        .then((res) => {
          // Création de l url
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
        })
        .catch((err) => {
          setIsLoading(false);
          errorFetch();
          console.log(err)
        });
    }
  };

  return (
    <div className="w-[540px] h-[80%] border-[1px] ">
      { facturationStateRight === 1 ?
        <>
          <div className="w-11/12 m-auto flex flex-col justify-start items-start">
            <p className="mt-5 font-semibold">Type d'export :</p>
            <select
              name="type"
              id="type"
              className="border-[1px] h-9 rounded-sm mt-2 p-1 font-thin"
              onChange={(e) => setBillingTypeSelected(e.target.value)}
            >
              {billingTypes.map((data) => (
                <option value={data}>{data}</option>
              ))}
            </select>
            <p className="mt-10 font-semibold">Du :</p>
            <div className="w-full flex justify-between items-center">
              <DateExercice type="du" />
            </div>
            <p className="mt-5 font-semibold">Au :</p>
            <div className="w-full flex justify-between items-center ">
              <DateExercice type="au" />
            </div>

            {billingTypeSelected === "Journal de facturation client" ? (
              <>
                <p className="mt-10 font-semibold">Dossier :</p>
                <ClientFileSelect />

                <p className="mt-10 font-semibold">Classeur :</p>
                <BinderSelect />
              </>
            ) : null}
          </div>
          <div className="w-full flex justify-center items-center mt-10">
            <button
              className="w-40 h-8 border-[1px] rounded-sm border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer"
              onClick={handlePdfBilling}
            >
              Générer le rapport
            </button>
          </div>
        </> : 
        <div className="w-11/12 m-auto">
          <p className="italic text-center mt-4">Vous n'avez pas les droits pour accéder à cette fonctionnalité</p>
        </div> 
      }
    </div>
  );
};

export default Billing;
