/* eslint-disable no-irregular-whitespace */
import { useRightStateContext } from "../../../context/RightStateContext";
import { useDateExerciceStore } from "../../../store/FinancialStatement/dateExerciceStore";
import { useClientFileStore } from "../../../store/FinancialStatement/inputStore";
import { useTabsFSStore } from "../../../store/FinancialStatement/tabsStore";
import { useLoadingStore } from "../../../store/utils/loading";
import {
  getStateInFlowsJournalByDosRef,
  getStateInFlowsJournalByPdos,
} from "../../../utils/FinancialStatement/state";
import { convertToServerDateFormat } from "../../../utils/functionService";
import BinderSelect from "../select/BinderSelect";
import ClientFileSelect from "../select/ClientFileSelect";
import DateExercice from "../select/ExerciceSelect";
/**
 * @component
 * @name CashInFlow
 * @description
  * Un composant qui génère un rapport PDF pour le flux de trésorerie du client.
  * Le seul type de facturation pris en charge est le "Journal des encaissements".
  *
  * Le composant permet également aux utilisateurs de filtrer le rapport par plage de dates et
  * de sélectionner le dossier client et le classeur.
  *
  * Remarque ce composant n'est pas utilisé dans la version actuelle de l'application.
  *
 * @author Iris Succi
 * @date 2023
 */
const CashInFlow = () => {
  //Context
  const {
    cashInFlowTypes,
    cashInFlowTypeSelected,
    setCashInFlowTypeSelected,
    setDocumentUrl,
  } = useTabsFSStore();
  const { encaissementStateRight } = useRightStateContext();
  const { selectedBinder, selectedClientFile } = useClientFileStore();
  const { du, au } = useDateExerciceStore();
  const { setIsLoading } = useLoadingStore();

  const startDate = convertToServerDateFormat(du) + "-01";
  const endDate = new Date(convertToServerDateFormat(au));
  endDate.setMonth(endDate.getMonth() + 1);
  endDate.setDate(0);
  const formattedEndDate = `${endDate.getFullYear()}-${String(
    endDate.getMonth() + 1
  ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;

  const handlePdfCashInFlow = () => {
    setIsLoading(true);
    if (selectedBinder) {
      getStateInFlowsJournalByPdos(selectedBinder, startDate, formattedEndDate)
        .then((res) => {
          // Create a URL from the blob
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
        })
        .catch((error) => console.log(error));
    } else if (selectedClientFile !== 0 && cashInFlowTypeSelected) {
      getStateInFlowsJournalByDosRef(
        selectedClientFile,
        startDate,
        formattedEndDate
      )
        .then((res) => {
          // Create a URL from the blob
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="w-[540px] h-[80%] border-[1px] ">
      {encaissementStateRight === 1 ? (
        <>
          <div className="w-11/12 m-auto flex flex-col justify-start items-start">
            <p className="mt-5 font-semibold">Type d'export :</p>
            <select
              name="type"
              id="type"
              value={cashInFlowTypes}
              className="border-[1px] h-9 rounded-sm mt-2 p-1 font-thin"
              onChange={(e) => setCashInFlowTypeSelected(e.target.value)}
            >
              {cashInFlowTypes.map((data) => (
                <option value={data}>{data}</option>
              ))}
            </select>

            <p className="mt-10 font-semibold">Du :</p>
            <div className="w-full flex justify-between items-center">
              <DateExercice type="du" />
            </div>
            <p className="mt-5 font-semibold">Au :</p>
            <div className="w-full flex justify-between items-center ">
              <DateExercice type="au" />
            </div>

            <p className="mt-10 font-semibold">Dossier :</p>
            <ClientFileSelect />

            <p className="mt-10 font-semibold">Classeur :</p>
            <BinderSelect />
          </div>
          <div className="w-full flex justify-center items-center mt-10">
            <button
              className="w-40 h-8 border-[1px] rounded-sm border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer"
              onClick={handlePdfCashInFlow}
            >
              Générer le rapport
            </button>
          </div>
        </>
      ) : (
        <div className="w-11/12 m-auto">
          <p className="text-center italic mt-4">
            Vous n'avez pas accès à cette fonctionnalité
          </p>
        </div>
      )}
    </div>
  );
};

export default CashInFlow;
