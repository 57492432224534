import { useEffect } from "react";
import { amountsStore } from "../../../../store/Invoice/amountsStore";
import {
  calculateHtFromTtc,
  calculateTVA,
} from "../../../../utils/functionService";
import { getTVA } from "../../../../utils/Invoice/fees";
import { useCreateFee } from "../../../../store/Invoice/createFeeStore";

const Amounts = () => {
  const {
    unitPrice,
    setUnitPrice,
    quantity,
    setQuantity,
    ht,
    setHt,
    tva,
    setTva,
    tvaRate,
    setTvaRate,
    ttc,
    setTtc,
    selectedTvaRate,
    setSelectedTvaRate,
  } = amountsStore();
  const { selectedLibelle } = useCreateFee();

  useEffect(() => {
    getTVA()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.TVA_CLE,
          label: file.TVA_VALEUR,
        }));
        setTvaRate(formattedFiles);
      })
      .catch((err) => console.log(err));
    setSelectedTvaRate(2);
  }, []);

  /**
   * @function
   * @name handleInputChange
   * @author Succi Iris
   * @date 2024
   * @description A function to calculate tva and ttc when we change ht and reverse.
   * @param {object} e - The event object.
   */
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "ht") {
      const fieldValue = value === "" ? "" : parseFloat(value);
      if (fieldValue === "" || fieldValue === 0) {
        setTva(0);
        setTtc(0);
      } else {
        const { newTva, newTtc, newUnitPrice } = calculateTVA(
          fieldValue,
          selectedTvaRate,
          quantity
        );
        setTva(newTva);
        setTtc(newTtc);
        setUnitPrice(newUnitPrice);
      }
    } else if (name === "ttc") {
      const fieldValue = value === "" ? "" : parseFloat(value);
      if (fieldValue === "" || fieldValue === 0) {
        setTva(0);
        setHt(0);
      } else {
        const { newTva, newHt, newUnitPrice } = calculateHtFromTtc(
          fieldValue,
          selectedTvaRate,
          quantity
        );
        setTva(newTva);
        setHt(newHt);
        setUnitPrice(newUnitPrice);
      }
    } else if (name === "txTva") {
      const fieldValue = parseFloat(value);
      setSelectedTvaRate(fieldValue);
    }
  };

  useEffect(() => {
    if (quantity > 0 && unitPrice > 0) {
      const newHt = quantity * unitPrice;
      const { newTva, newTtc } = calculateTVA(newHt, selectedTvaRate, quantity);
      setHt(newHt);
      setTva(newTva);
      setTtc(newTtc);
      setQuantity(quantity);
    }
  }, [quantity, unitPrice, selectedTvaRate, selectedLibelle]);

  useEffect(() => {
    if (ht > 0) {
      const { newTva, newTtc, newUnitPrice } = calculateTVA(
        ht,
        selectedTvaRate,
        quantity
      );
      if (!isNaN(newTtc) && !isNaN(newTva) && !isNaN(newUnitPrice)) {
        setTva(newTva);
        setTtc(newTtc);
        setUnitPrice(newUnitPrice);
      }
    } else if (ttc > 0) {
      const { newTva, newHt, newUnitPrice } = calculateHtFromTtc(
        ttc,
        selectedTvaRate,
        quantity
      );
      if (!isNaN(newHt) && !isNaN(newTva) && !isNaN(newUnitPrice)) {
        setTva(newTva);
        setHt(newHt);
        setUnitPrice(newUnitPrice);
      }
    }
  }, [selectedTvaRate, ht]);

  useEffect(() => {
    if (ht === 0 || isNaN(ht)) {
      setTva(0);
      setTtc(0);
      setQuantity(0);
      setUnitPrice(0);
    }
  }, [ht]);

  return (
    <div className="grid grid-cols-12 gap-1 w-full my-5">
      <div className="col-start-1 col-end-2 mt-1 text-sm ">H.T :</div>
      <input
        type="number"
        name="ht"
        value={ht}
        className="w-full border-[1px] rounded border-gray-300 h-8"
        onChange={(e) => {
          setHt(parseInt(e.target.value));
          handleInputChange(e);
        }}
      />
      <div className="col-start-3 col-end-4 mt-1 text-sm">TX TVA :</div>
      <select
        name="txTva"
        id="txTva"
        className="w-full text-sm border-[1px] rounded border-gray-300 h-8 py-1 z-20 "
        value={selectedTvaRate}
        onChange={(e) => {
          setSelectedTvaRate(Number(e.target.value));
        }}
      >
        <option value={""}></option>
        {tvaRate?.map((tvaRate, i) => (
          <option key={i} value={tvaRate?.value}>
            {tvaRate?.label} %
          </option>
        ))}
      </select>
      <div className="col-start-5 text-sm col-end-6 mt-1 ml-3">TVA :</div>
      <input
        type="number"
        value={tva}
        readOnly
        className="w-full border-[1px] rounded border-gray-300 h-8"
        onChange={(e) => {
          setTva(parseInt(e.target.value));
        }}
      />
      <div className="col-start-7 col-end-8 text-sm mt-1 ml-4">Qté :</div>
      <input
        type="number"
        name="quantity"
        value={quantity}
        className="w-full border-[1px] rounded border-gray-300 h-8"
        onChange={(e) => {
          setQuantity(parseInt(e.target.value));
          handleInputChange(e);
        }}
      />
      <div className="col-start-9 col-end-10 text-sm mt-1 ml-4">PU :</div>
      <input
        name="unitPrice"
        type="number"
        value={unitPrice}
        className="w-full border-[1px] rounded border-gray-300 h-8"
        onChange={(e) => {
          setUnitPrice(parseInt(e.target.value));
          handleInputChange(e);
        }}
      />
      <div className="col-start-11 col-end-12 text-sm mt-1 ml-3">T.T.C :</div>
      <input
        type="number"
        name="ttc"
        value={ttc}
        className="w-full border-[1px] rounded border-gray-300 h-8"
        onChange={(e) => {
          setTtc(parseInt(e.target.value));
          handleInputChange(e);
        }}
      />
    </div>
  );
};

export default Amounts;
