import { create } from 'zustand';
import { dataMonthnInterval } from '../../interface/data/time';
import { allUsers } from '../../interface/data/user';
import { listTimGeneratePdf } from '../../utils/Pdfs/listTimeGeneratePdf';

type ListStore = {
    users: allUsers[];
    setUsers: (users: allUsers[]) => void;

    dataListTime: any[];
    setDataListTime: (data: any) => void;

    dataFilterList: dataMonthnInterval[];
    setDataFilterList: (data: dataMonthnInterval[]) => void;

    selectedItems: string[];
    setSelectedItems: (items: string[]) => void;

    filteredData: dataMonthnInterval[];
    setFilteredData: (data: dataMonthnInterval[]) => void;

    currentItems: any[];
    setCurrentItems: (items: any[]) => void;

    resetDataListTime: () => void;

    generatePDF: () => void;
    initializeGeneratePDF: () => void;

    selectedTimeForValidated: any[];
    setSelectedTimeForValidated: (update: any[] | ((prev: any[]) => any[])) => void;

    selectedAllTimeForValidated: boolean;
    setSelectedAllTimeForValidated: (value: boolean | ((prev: boolean) => boolean)) => void;

    blockSelectedTimeForValidated: boolean;
    setBlockSelectedTimeForValidated: (value: boolean | ((prev: boolean) => boolean)) => void;

    createManualFee: boolean;
    setCreateManualFee: (value: boolean | ((prev: boolean) => boolean)) => void;
}   

export const useListStore = create<ListStore>((set, get) => ({
    users: [],
    setUsers: (users) => set({ users }),

    dataListTime: [],
    
    setDataListTime: (data: any) => {
        const { dataListTime: prevDataListTime } = get();
        const index = prevDataListTime.findIndex((item: any) => {
            return item.ANA_DATEREALISATION === data.ANA_DATEREALISATION;
        });

        if (index !== -1 && data.ANA_COMPLEMENTLIB !== "") {
            // Si un objet avec la même date existe déjà et que le nouveau commentaire n'est pas vide
            // Remplacer l'objet existant par le nouveau
            set({
                dataListTime: [
                    ...prevDataListTime.slice(0, index),
                    data,
                    ...prevDataListTime.slice(index + 1),
                ]
            });
        } else if (index === -1) {
            // Si aucun objet avec la même date n'existe, ajouter le nouvel objet
            set({ dataListTime: [...prevDataListTime, data] });
        } else {
            // Si un objet avec la même date existe mais que le nouveau commentaire est vide, ne rien faire
            return;
        }
    },
    
    dataFilterList: [],
    setDataFilterList: (data) => set({ dataFilterList: data }),
    resetDataListTime: () => set({ dataListTime: [] }),
    filteredData: [],
    setFilteredData: (data) => set({ filteredData: data }),

    selectedItems: [
        "Etat",
        "Dossier",
        "Act.",
        "Date",
        "Heure",
        "Tx pond.",
        "Libellé",
        "Mission",
        "Hon. val",
        "NF",
    ],
    currentItems: [],
    setCurrentItems: (items) => set({ currentItems: items }),
    setSelectedItems: (items) => set({ selectedItems: items }),
    generatePDF: () => {},
    initializeGeneratePDF: () => {
        set({
            generatePDF: () => {
                const current = get();
                listTimGeneratePdf(current.selectedItems, current.dataFilterList);
            }
        });
    },

    selectedTimeForValidated: [] as any[],
    setSelectedTimeForValidated: (update: any[] | ((prev: any[]) => any[])) =>
        typeof update === 'function' ? set((state) => ({ selectedTimeForValidated: update(state.selectedTimeForValidated) }))
            : set({ selectedTimeForValidated: update }),

    selectedAllTimeForValidated: false,
    setSelectedAllTimeForValidated: (value: boolean | ((prev: boolean) => boolean)) =>
        typeof value === 'function' ? set((state) => ({ selectedAllTimeForValidated: value(state.selectedAllTimeForValidated) }))
            : set({ selectedAllTimeForValidated: value }),

    blockSelectedTimeForValidated: false,
    setBlockSelectedTimeForValidated: (value: boolean | ((prev: boolean) => boolean)) =>
        typeof value === 'function' ? set((state) => ({ blockSelectedTimeForValidated: value(state.blockSelectedTimeForValidated) }))
            : set({ blockSelectedTimeForValidated: value }),

    createManualFee: false,
    setCreateManualFee: (value: boolean | ((prev: boolean) => boolean)) =>
        typeof value === 'function' ? set((state) => ({ createManualFee: value(state.createManualFee) }))
            : set({ createManualFee: value }),
}));
