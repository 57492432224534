import { useEffect } from "react";
import { useCreateFeesOrInvoices } from "../../../store/Invoice/buttonsStore";
import { useTabsStore } from "../../../store/Invoice/tabsStore";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import "../../../css/animations.css";
import { deleteFee } from "../../../utils/Invoice/fees";
import { useLoadingStore } from "../../../store/utils/loading";
import {
  deleteProforma,
  postRelaunch,
  sendEmailForInvoice,
  validateProforma,
} from "../../../utils/Invoice/invoicing";
import { createAcompteStore } from "../../../store/Invoice/createAcompte";
import { deletePrepayment } from "../../../utils/Invoice/prepayments";
import { errorSendNewTime, successSendMail } from "../../../utils/toaster";
import { useCreateProformaStore } from "../../../store/Invoice/createProforma";
import { deleteCredit, validateCredits } from "../../../utils/Invoice/credits";
import { useCreateCreditStore } from "../../../store/Invoice/createCredit";
import { dataInvoicesFeelsStore } from "../../../store/Invoice/dataInvoicesFeelsStore";
import { deleteCashInFlow } from "../../../utils/Invoice/cashInFlow";
import { useCreateCashInFlowStore } from "../../../store/Invoice/createCashInFlowStore";
import { useSlip } from "../../../store/Invoice/createSlip";
import { deleteSlip } from "../../../utils/Invoice/slips";
import { getFormattedDateEN2 } from "../../../utils/datepicker";
import SelectRelaunch from "./SelectRelaunch";
import { useInvoiceStore } from "../../../store/Invoice/invoice";
import { useRightContext } from "../../../context/RightContext";
import { useRightInvoiceContext } from "../../../context/RightInvoiceContext";
import { useCreateFee } from "../../../store/Invoice/createFeeStore";
import { useCreateSchedule } from "../../../store/Invoice/schedule";

/**
 * @component
 * @name CreateFeeOrInvoice
 * @description
 * Un composant qui affiche des boutons pour créer, modifier et supprimer des frais, des factures, des provisions, des acomptes et des proformas. *
 * @param {object} createFees - The store for creating fees.
 * @param {function} setCreateFees - The function to set the createFees store to the given value.
 * @param {object} createInvoices - The store for creating invoices.
 * @param {function} setCreateInvoices - The function to set the createInvoices store to the given value.
 * @param {object} createCredit - The store for creating credits.
 * @param {function} setCreateCredit - The function to set the createCredit store to the given value.
 * @param {object} creditsFilter - The store for filtering the list of credits displayed.
 * @param {object} selectedIdsInvoices - The array of invoice ids selected by the user.
 * @param {function} setSelectedIdsInvoices - The function to set the selectedIdsInvoices store to the given value.
 * @param {function} handleDeleteProforma - The function to handle deleting a proforma.
 * @param {function} handleDeleteAcompte - The function to handle deleting an acompte.
 * @param {function} handleDeleteFee - The function to handle deleting a fee.
 * @param {function} handleDeleteCredit - The function to handle deleting a credit.
 * @param {object} createProvision - The store for creating provisions.
 * @param {function} setCreateProvision - The function to set the createProvision store to the given value.
 * @param {function} handleValidateProforma - The function to handle validating a proforma.
 */
const CreateFeeOrInvoice = () => {
  //Context
  const {
    acompteRight,
    avoirRight,
    bordereauxRight,
    provisionRight,
    encaissementRight,
    factureRight,
    relanceRight,
  } = useRightInvoiceContext();

  // Store
  const {
    createFees,
    setCreateFees,
    setCreateInvoices,
    setCreateCredit,
    createProvision,
    setCreateProvision,
    setCreateAcompte,
    createProformat,
    setCreateProformat,
    setCreateCashInFlow,
    setVisuPdf,
    setCreateUnpaid,
    setCreateSlip,
    setCreateSchedule,
  } = useCreateFeesOrInvoices();
  const { choiceTab, setChoiceTab, setSendData } = useTabsStore();
  const { abaRef, setAbaRef } = useSlip();
  const { creditsFilter, setFeesFilter } = dataInvoicesFeelsStore();
  const { setSendFee, setModeFee } = useCreateFee();
  const { setIsLoading } = useLoadingStore();
  const { setModeAcompte } = createAcompteStore();
  const { setModeProforma } = useCreateProformaStore();
  const { setModeCredit } = useCreateCreditStore();
  const { setModeCashInFlow } = useCreateCashInFlowStore();
  const { setModeSchedule } = useCreateSchedule();
  const { selectedRelaunch } = useInvoiceStore();
  const { chrono } = useRightContext();

  const {
    selectedIdsInvoices,
    setSelectedIdsInvoices,
    setDisplayDiv,
    setDisplayDivFee,
    setDisplayDivVisuPdf,
    setDisplayDivSchedule,
    isPointedOut,
  } = useCreateInvoiceStore();

  useEffect(() => {
    setCreateFees(false);
    setCreateInvoices(false);
    setCreateCredit(false);
    setCreateProformat(false);
  }, [choiceTab]);

  const isSelectedInvoiceValidated = creditsFilter.some(
    (credit) => credit.ANA_REF === selectedIdsInvoices[0] && credit.isValidated
  );

  /**
   * @function
   * @name handleDeleteProforma
   * @description
   * Supprime une profoma
   * @author Succi Iris
   * @param {array} selectedIdsInvoices - The array of invoice ids to be deleted.
   */
  const handleDeleteProforma = async () => {
    setIsLoading(true);
    try {
      const response = await deleteProforma(selectedIdsInvoices);
      setSelectedIdsInvoices((_prev) => []);
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setIsLoading(false);
      setFeesFilter([]);
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name handleDeleteAcompte
   * @description
   * Supprime un acompte
   * @author Succi Iris
   * @param {array} selectedIdsInvoices - The array of invoice ids to be deleted.
   */
  const handleDeleteAcompte = async () => {
    setIsLoading(true);
    try {
      const response = await deletePrepayment(selectedIdsInvoices);
      setSelectedIdsInvoices((_prev) => []);
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setIsLoading(false);
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name handleDeleteFee
   * @description Supprime un honoraire
   * @author Succi Iris
   * @param {array} selectedIdsInvoices - The array of invoice ids to be deleted.
   */
  const handleDeleteFee = async () => {
    setIsLoading(true);
    try {
      const response = await deleteFee(String(selectedIdsInvoices));
      setSelectedIdsInvoices((_prev) => []);
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setIsLoading(false);
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name handleDeleteCredit
   * @description
   * supprime un avoir
   * @author Succi Iris
   * @param {array} selectedIdsInvoices - The array of invoice ids to be deleted.
   */
  const handleDeleteCredit = async () => {
    setIsLoading(true);

    try {
      const response = await deleteCredit(String(selectedIdsInvoices));
      setSelectedIdsInvoices((_prev) => []);
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setIsLoading(false);
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name handleValidateProforma
   * @description
   * Valide une proforma
   * @author Succi Iris
   * @param {array} selectedIdsInvoices - The array of invoice ids to be validated.
   */
  const handleValidateProforma = async () => {
    setIsLoading(true);
    try {
      const response = await validateProforma(String(selectedIdsInvoices));
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setChoiceTab("Facture");
      setVisuPdf(true);
      setDisplayDivVisuPdf(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name handleDelete
   * @description
   * Supprime un encaissement
   * @author Succi Iris
   * @param {array} selectedIdsInvoices - The array of invoice ids to be deleted.
   */
  const handleDeleteCashInFlow = async () => {
    setIsLoading(true);
    try {
      const response = await deleteCashInFlow(String(selectedIdsInvoices));
      setSelectedIdsInvoices((_prev) => []);
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setIsLoading(false);
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name handleValidateCredits
   * @description
   * Valide un avoir
   * @author Succi Iris
   * @param {array} selectedIdsInvoices - The array of invoice ids to be validated.
   */
  const handleValidateCredits = async () => {
    setIsLoading(true);
    try {
      const response = await validateCredits(String(selectedIdsInvoices));
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setChoiceTab("Avoir");
      setVisuPdf(true);
      setDisplayDivVisuPdf(true);
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name handleSendEmailForInvoice
   * @description
   * Envoi un mail pour chaque facture
   * @author Succi Iris
   * @param {array} selectedIdsInvoices - The array of invoice ids to be validated.
   */
  const handleSendEmailForInvoice = async () => {
    setIsLoading(true);
    try {
      for (let i = 0; i < selectedIdsInvoices.length; i++) {
        const response = await sendEmailForInvoice(selectedIdsInvoices[i]);
        console.log("Succès:", response);
      }
      setSendData((prev) => !prev);
      setChoiceTab("Facture");
      setSelectedIdsInvoices((_prev) => []);
      setIsLoading(false);
      successSendMail();
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name handleDeleteSlip
   * @description
   * Gère la suppression d'un bordereau.
   * @author Succi Iris
   * @param {array} selectedIdsInvoices - The array of invoice ids to be deleted.
   */
  const handleDeleteSlip = async () => {
    setIsLoading(true);
    const date = getFormattedDateEN2(selectedIdsInvoices[0].date);
    try {
      const response = await deleteSlip(date, abaRef);
      setSelectedIdsInvoices((_prev) => []);
      console.log("Succès:", response);
      setAbaRef(0);
      setSendData((prev) => !prev);
      setIsLoading(false);
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name handleRelaunch
   * @description
   * Gère les relances
   * @date 13/02/2024
   * @author Succi Iris
   * @param {array} selectedIdsInvoices - The array of invoice ids to be relaunched.
   */
  const handleRelaunch = async () => {
    setIsLoading(true);
    try {
      const response = await postRelaunch(
        selectedIdsInvoices,
        selectedRelaunch
      );
      setSelectedIdsInvoices((_prev) => []);
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setIsLoading(false);
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  return (
    <div className="flex justify-start items-center ">
      {choiceTab === "Honoraire" &&
      !createFees &&
      !createProformat &&
      factureRight === 1 ? (
        <>
          <button
            className="w-auto h-8 px-2 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white mr-5 shadow-md"
            onClick={() => {
              setSendFee(false);
              setCreateFees(true);
              setDisplayDivFee(true);
              setModeFee("create");
            }}
          >
            Créer un élément facturable
          </button>
          <button
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md cursor-pointer mr-5"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none"
            }
            disabled={selectedIdsInvoices.length === 0}
            onClick={() => {
              setDisplayDiv(true);
              setCreateProformat(true);
              setModeProforma("create");
            }}
          >
            Créer une proforma
          </button>
        </>
      ) : null}

      {choiceTab === "Honoraire" &&
      selectedIdsInvoices.length > 0 &&
      factureRight === 1 ? (
        <>
          <button
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-5"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-5"
            }
            disabled={selectedIdsInvoices.length === 0}
            onClick={() => {
              setCreateFees(true);
              setDisplayDivFee(true);
              setModeFee("edit");
            }}
          >
            Modifier
          </button>
          <button
            onClick={() => handleDeleteFee()}
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500  hover:bg-rose-500  text-white shadow-md cursor-pointer"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none"
            }
          >
            Supprimer
          </button>
        </>
      ) : null}

      {choiceTab === "Facture" &&
      selectedIdsInvoices.length > 0 &&
      factureRight === 1 ? (
        <>
          <button
            className={
              selectedIdsInvoices.length > 0 && selectedIdsInvoices.length <= 1
                ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0}
            onClick={() => {
              setDisplayDivVisuPdf(true);
              setVisuPdf(true);
            }}
          >
            Visualiser
          </button>
          <button
            className={
              selectedIdsInvoices.length > 0 && chrono === 1
                ? "w-44 h-8 border-[1px] rounded-md border-bc-blue bg-bc-blue hover:border-bc-blue-pale hover:bg-bc-blue-pale text-white shadow-md cursor-pointer mr-4"
                : "w-44 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0 && chrono === 1}
            onClick={() => handleSendEmailForInvoice()}
          >
            Envoyez par mail
          </button>
          <button
            className={
              selectedIdsInvoices.length > 0 && selectedIdsInvoices.length <= 1
                ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer  mr-4"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none"
            }
            disabled={selectedIdsInvoices.length === 0 && avoirRight === 0}
            onClick={() => {
              setCreateCredit(true);
              setDisplayDiv(true);
              setModeCredit("create");
            }}
          >
            Créer un avoir
          </button>
          <button
            className={
              selectedIdsInvoices.length > 0 && selectedIdsInvoices.length <= 1
                ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none"
            }
            disabled={selectedIdsInvoices.length > 1}
            onClick={() => {
              setDisplayDivSchedule(true);
              setModeSchedule("create");
              setCreateSchedule(true);
            }}
          >
            Paiement
          </button>
        </>
      ) : null}

      {choiceTab === "Proformat" && factureRight === 1 ? (
        <>
          <button
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md cursor-pointer mr-4"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0}
            onClick={handleValidateProforma}
          >
            Valider en facture
          </button>
          <button
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0}
            onClick={() => {
              setDisplayDivVisuPdf(true);
              setVisuPdf(true);
            }}
          >
            Visualiser
          </button>
          <button
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0}
            onClick={() => {
              setCreateProformat(true);
              setDisplayDiv(true);
              setModeProforma("edit");
            }}
          >
            Modifier
          </button>
          <button
            onClick={() => handleDeleteProforma()}
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500  hover:bg-rose-500  text-white shadow-md cursor-pointer"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none"
            }
          >
            Supprimer
          </button>
        </>
      ) : null}

      {choiceTab === "Provision" && !createProvision && provisionRight === 1 ? (
        <>
          <button
            className="w-auto px-2 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
            onClick={() => {
              setCreateProvision(true);
              setDisplayDiv(true);
            }}
          >
            Créer une provision
          </button>
        </>
      ) : null}

      {choiceTab === "Provision" &&
      selectedIdsInvoices.length > 0 &&
      provisionRight === 1 ? (
        <>
          <button
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0}
            onClick={() => {
              setCreateProformat(true);
              setDisplayDiv(true);
              setModeProforma("edit");
            }}
          >
            Modifier
          </button>
          <button
            onClick={() => handleDeleteProforma()}
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500  hover:bg-rose-500  text-white shadow-md cursor-pointer"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none"
            }
          >
            Supprimer
          </button>
        </>
      ) : null}

      {choiceTab === "Bordereau" && bordereauxRight === 1 ? (
        <>
          <button
            className={
              "w-44 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4 "
            }
            onClick={() => {
              setCreateSlip(true);
            }}
          >
            Générer un bordereau
          </button>
          <button
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0}
            onClick={() => {
              setDisplayDivVisuPdf(true);
              setVisuPdf(true);
            }}
          >
            Visualiser
          </button>
          <button
            onClick={() => handleDeleteSlip()}
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500  hover:bg-rose-500  text-white shadow-md cursor-pointer"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none"
            }
          >
            Supprimer
          </button>
        </>
      ) : null}

      {choiceTab === "Acompte" &&
      selectedIdsInvoices.length === 0 &&
      acompteRight === 1 ? (
        <>
          <button
            className="w-auto px-2 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
            onClick={() => {
              setCreateAcompte(true);
              setDisplayDiv(true);
              setModeAcompte("create");
            }}
          >
            Créer un acompte
          </button>
        </>
      ) : choiceTab === "Acompte" &&
        selectedIdsInvoices.length >= 1 &&
        acompteRight === 1 ? (
        <>
          <button
            className={
              selectedIdsInvoices.length <= 1
                ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0}
            onClick={() => {
              setCreateAcompte(true);
              setDisplayDiv(true);
              setModeAcompte("edit");
            }}
          >
            Modifier
          </button>
          <button
            onClick={() => handleDeleteAcompte()}
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500  hover:bg-rose-500  text-white shadow-md cursor-pointer"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none"
            }
          >
            Supprimer
          </button>
        </>
      ) : null}
      {choiceTab === "Avoir" &&
      selectedIdsInvoices.length > 0 &&
      avoirRight === 1 ? (
        <>
          <button
            className={
              selectedIdsInvoices.length > 0 && selectedIdsInvoices.length <= 1
                ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0}
            onClick={() => {
              setDisplayDivVisuPdf(true);
              setVisuPdf(true);
            }}
          >
            Visualiser
          </button>
          <button
            className={
              selectedIdsInvoices.length > 0 && chrono === 1
                ? "w-44 h-8 border-[1px] rounded-md border-bc-blue bg-bc-blue hover:border-bc-blue-pale hover:bg-bc-blue-pale text-white shadow-md cursor-pointer mr-4"
                : "w-44 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0 && chrono === 1}
            onClick={() => handleSendEmailForInvoice()}
          >
            Envoyez par mail
          </button>
          {!isSelectedInvoiceValidated ? (
            <>
              <button
                className={
                  selectedIdsInvoices.length > 0 &&
                  selectedIdsInvoices.length <= 1
                    ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
                    : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
                }
                disabled={selectedIdsInvoices.length === 0}
                onClick={() => {
                  setCreateCredit(true);
                  setDisplayDiv(true);
                  setModeCredit("edit");
                }}
              >
                Modifier
              </button>
              <button
                className={
                  selectedIdsInvoices.length > 0
                    ? "w-40 h-8 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md cursor-pointer mr-4"
                    : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
                }
                disabled={selectedIdsInvoices.length === 0}
                onClick={() => handleValidateCredits()}
              >
                Valider
              </button>
              <button
                onClick={() => handleDeleteCredit()}
                className={
                  selectedIdsInvoices.length > 0
                    ? "w-40 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500  hover:bg-rose-500  text-white shadow-md cursor-pointer"
                    : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none"
                }
              >
                Supprimer
              </button>
            </>
          ) : null}
        </>
      ) : null}
      {choiceTab === "Encaissement" && encaissementRight === 1 ? (
        <>
          <button
            className="w-auto px-2 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
            onClick={() => {
              setCreateCashInFlow(true);
              setDisplayDiv(true);
              setModeCashInFlow("create");
            }}
          >
            Créer un encaissement
          </button>
          <button
            className="w-auto px-2 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-4"
            onClick={() => {
              setCreateCashInFlow(true);
              setDisplayDiv(true);
              setModeCashInFlow("edit");
            }}
          >
            Pointer
          </button>
          <button
            onClick={() => handleDeleteCashInFlow()}
            className={
              selectedIdsInvoices.length > 0
                ? "w-40 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500  hover:bg-rose-500  text-white shadow-md cursor-pointer mr-20"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-20"
            }
          >
            Supprimer
          </button>
          <button
            className={
              selectedIdsInvoices.length > 0 && isPointedOut
                ? "w-40 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500  hover:bg-rose-500  text-white shadow-md cursor-pointer mr-20"
                : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-20"
            }
            onClick={() => {
              setCreateUnpaid(true);
            }}
          >
            Générer un impayé
          </button>
        </>
      ) : null}

      {choiceTab === "Relance" && relanceRight === 1 ? (
        <>
          <SelectRelaunch />
          <button
            className={
              selectedIdsInvoices.length > 0 && chrono === 1
                ? "w-44 ml-4 h-8 border-[1px] rounded-md border-bc-blue bg-bc-blue hover:border-bc-blue-pale hover:bg-bc-blue-pale text-white shadow-md cursor-pointer mr-4"
                : "w-44  ml-4 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-4"
            }
            disabled={selectedIdsInvoices.length === 0 && chrono === 1}
            onClick={() => handleRelaunch()}
          >
            Relancer
          </button>
        </>
      ) : null}
    </div>
  );
};

export default CreateFeeOrInvoice;
